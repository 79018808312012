<template>
  <section>
    <div :style="{ margin: '20px' }">
      <div
        :style="{
          'max-width': '750px',
          'text-align': 'left',
          'margin-left': 'auto',
          'margin-right': 'auto',
          display: 'flex',
        }"
      >
        <a-dropdown :style="{ 'min-width': '130px' }">
          <a class="ant-dropdown-link" style="font-size: 14px">
            Кол-во тестов <a-icon type="down" />
          </a>
          <a-menu slot="overlay" @click="handleMenuClick">
            <a-menu-item :key="totalrecords">
              <a> Все </a>
            </a-menu-item>
            <a-menu-item key="10">
              <a> 10 тестов </a>
            </a-menu-item>
            <a-menu-item key="20">
              <a> 20 тестов </a>
            </a-menu-item>
            <a-menu-item key="30">
              <a> 30 тестов </a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
    </div>
    <section class="tabsResultsNew">
      <!--  -->
      <SondiChart
        ident="chartOne"
        ref="chartOne"
        :title="$t('test-szondi.front')"
        :result-label-string="$t('test-szondi.resultFront')"
        :data-plus="getData(period).frontPlus"
        :data-minus="getData(period).frontMinus"
        :total="totalrecords"
        :vectors-values="getData(period).frontResult"
      />
      <SondiChart
        ident="chartTwo"
        ref="chartTwo"
        :title="$t('test-szondi.back')"
        :result-label-string="$t('test-szondi.resultBack')"
        :data-plus="getData(period).backPlus"
        :data-minus="getData(period).backMinus"
        :total="totalrecords"
        :vectors-values="getData(period).backResult"
      />
    </section>
  </section>
</template>

<script>
import SondiChart from "./SondiChart.vue";
export default {
  components: {
    SondiChart,
  },
  props: {
    results: {
      default: () => [],
      type: Array,
    },
  },
  data() {
    return {
      resultedData: undefined,
      totalrecords: 1,
      // /
      period: 0,
      total: 1,
    };
  },
  computed: {},
  mounted() {
    this.total = this.results.length;
    this.period = this.results.length;
  },
  methods: {
    getData(period) {
      const newresults = this.results.slice(0, period);

      const frontPlusValues = Array(8).fill(0);
      const frontMinusValues = Array(8).fill(0);
      const backPlusValues = Array(8).fill(0);
      const backMinusValues = Array(8).fill(0);
      const backResult = Array(8).fill("");
      const frontResult = Array(8).fill("");

      let totalrecords = 0;

      for (let i = 0; i < newresults.length; i++) {
        if (newresults[i].testtype !== "Szondi") {
          continue;
        }
        totalrecords++;

        for (let j = 0; j < 8; j++) {
          frontPlusValues[j] += newresults[i].result.frontPlus[j];
          backPlusValues[j] += newresults[i].result.backPlus[j];
          frontMinusValues[j] += newresults[i].result.frontMinus[j];
          backMinusValues[j] += newresults[i].result.backMinus[j];
        }
      }

      totalrecords = totalrecords === 0 ? 1 : totalrecords;
      // console.log(
      //   frontPlusValues,
      //   frontMinusValues,
      //   backPlusValues,
      //   backMinusValues,
      //   totalrecords
      // );
      for (let i = 0; i < 8; i++) {
        frontPlusValues[i] = Math.floor(frontPlusValues[i] / totalrecords);
        backPlusValues[i] = Math.floor(backPlusValues[i] / totalrecords);
        frontMinusValues[i] = -Math.floor(-frontMinusValues[i] / totalrecords);
        backMinusValues[i] = -Math.floor(-backMinusValues[i] / totalrecords);
        frontResult[i] = this.CalculateResult(
          -frontMinusValues[i],
          frontPlusValues[i]
        );
        backResult[i] = this.CalculateResult(
          -backMinusValues[i],
          backPlusValues[i]
        );
      }
      // console.log(
      //   frontPlusValues,
      //   frontMinusValues,
      //   backPlusValues,
      //   backMinusValues,
      //   totalrecords
      // );
      this.totalrecords = totalrecords === 0 ? 1 : totalrecords;
      return {
        frontPlus: frontPlusValues,
        backPlus: backPlusValues,
        frontMinus: frontMinusValues,
        backMinus: backMinusValues,
        frontResult,
        backResult,
      };
    },
    CalculateResult(mCount, pCount) {
      // var arr = isFront ? this.frontData : this.backData;
      // 6: !
      // 5: !
      // 4: !
      // 2-3: symb
      // 0-1:
      const result =
        (pCount < 2 && mCount < 2 ? "0" : "") +
        (pCount >= 2 && mCount >= 2
          ? "±"
          : pCount >= 2
          ? "+"
          : mCount >= 2
          ? "–"
          : "") +
        (pCount >= 4 ? "!".repeat(pCount - 3) : "") +
        (mCount >= 4 ? "!".repeat(mCount - 3) : "");

      return result;
    },
    handleMenuClick(e) {
      this.period = e.key;
    },
  },
};
</script>
<style lang="scss" scoped>
.resultCaption,
.resultNormal {
  text-align: left;
  max-width: 350px;
}
.resultCaption {
  font-weight: bold;
}
.tabsResultsNew {
  width: 90vw;
  margin-left: auto;
  margin-right: auto;
  /* min-height: 700px; */
  // margin-top: 30px;

  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: center;

  margin-bottom: 30px;
  text-align: center;
  max-width: 800px;
}

.tabsResultsNew {
  /* width: 100vw; */
  max-width: 1000px;
  /* width: fit-content; */
}

.sondi-results
  > div.tabsResults.ant-tabs.ant-tabs-top.ant-tabs-line
  > div.ant-tabs-bar.ant-tabs-top-bar {
  text-align: center;
}

.resultsTitle {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: large;
}
</style>
