<template>
  <section>
    <div :style="{ margin: '20px' }">
      <div
        :style="{
          'max-width': '750px',
          'text-align': 'left',
          'margin-left': 'auto',
          'margin-right': 'auto',
          display: 'flex',
        }"
      >
        <a-input
          placeholder="поиск группы"
          v-model="searchValue"
          style="width: 200px"
          @change="onSearch"
        >
          <a-icon slot="addonAfter" type="search" />
        </a-input>
        <div style="margin-left: auto" v-if="isAdmin">
          <a-button type="primary" @click="addGroup"
            ><a-icon type="plus" /> Добавить Группу</a-button
          >
        </div>
      </div>
    </div>

    <a-table
      :scroll="{ x: 800 }"
      :dataSource="data"
      sticky
      class="components-table-demo-nested"
      :pagination="false"
    >
      <a-table-column title="Название" key="title" width="150px" align="left">
        <template slot-scope="text">
          <a @click="isAdmin ? editGroup(text) : null">
            {{ text.name }}
          </a>
        </template>
      </a-table-column>
      <a-table-column
        title="Пользователи"
        key="users"
        width="150px"
        align="center"
      >
        <template slot-scope="text">
          <span>
            {{ text.usersRegular.length > 0 ? text.usersRegular.length : "-" }}
          </span>
        </template>
      </a-table-column>
      <a-table-column
        v-if="isAdmin"
        title="Интерпретаторы"
        key="interpretations"
        width="150px"
        align="center"
      >
        <template slot-scope="text">
          <span>
            {{ text.moderators ? text.moderators.length : "-" }}
          </span>
        </template>
      </a-table-column>

      <span slot="expandedRowRender" slot-scope="text">
        <a-table :dataSource="text.usersRegular" :pagination="false">
          <a-table-column title="Имя" width="150px" align="left">
            <template slot-scope="text">
              <span>
                {{ text.firstname + " " + text.secondname }}
              </span>
            </template>
          </a-table-column>
          <a-table-column title="Последний Тест" width="150px" align="center">
            <template slot-scope="record">
              <a
                v-if="record.testresults && record.testresults.length > 0"
                target="_blank"
                :href="$strapi.baseUrl + '/results/' + getLastTestId(record)"
              >
                {{ getLastTestText(record) }}
              </a>
              <a v-else>{{ "-" }}</a>
            </template>
          </a-table-column>
          <a-table-column title="" width="150px" align="center">
            <template slot-scope="record">
              <span>
                <a-button type="primary" @click="viewUser(text, record)"
                  ><a-icon type="search" />
                </a-button>
                <a-button type="danger" @click="removeFromGroup(text, record)"
                  ><a-icon type="close" />
                </a-button>
              </span>
            </template>
          </a-table-column>
        </a-table>
      </span>
    </a-table>
    <!--  -->
    <a-drawer
      :title="isEditGroup ? 'Редактирование группы' : 'Создание группы'"
      :width="'100%'"
      @close="onClose"
      :visible="visible"
      :wrapStyle="{
        height: 'calc(100% - 108px)',
        overflow: 'auto',
        paddingBottom: '108px',
      }"
    >
      <span v-if="isEditGroup" style="margin-top: 10px; margin-bottom: 20px">
        {{ $strapi.baseUrl + "/join/" + urlJoin }}
        <a
          slot="actions"
          style="margin-left: 10px"
          @click="shareClicked($strapi.baseUrl + '/join/' + urlJoin)"
        >
          {{ $t("ui.share") }}
        </a>
      </span>
      <a-form :form="form" layout="vertical" hideRequiredMark>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="Название">
              <a-input
                v-decorator="[
                  'name',
                  {
                    rules: [
                      { required: true, message: 'Please enter user name' },
                    ],
                  },
                ]"
                placeholder="Please enter user name"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16" v-if="isAdmin">
          <a-col :span="24">
            <a-form-item label="Интерпретаторы">
              <UsersMultiple :tags="tagsAdmins" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="24">
            <a-form-item label="Пользователи">
              <UsersMultiple :tags="tagsUsers" />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <div
        :style="{
          position: 'absolute',
          left: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
        }"
      >
        <a-button :style="{ marginRight: '8px' }" @click="onClose">
          Отмена
        </a-button>
        <a-button @click.prevent.stop="onSubmitGroup" type="primary"
          >Сохранить</a-button
        >
      </div>
    </a-drawer>
    <!--  -->
    <a-drawer
      v-if="currentUser"
      :title="
        currentUser.firstname +
        ' ' +
        currentUser.secondname +
        ' (' +
        currentUser.email +
        ')'
      "
      :width="'100%'"
      @close="onCloseUserVice"
      :visible="visibleUserView"
      :wrapStyle="{
        height: 'calc(100%)',
        overflow: 'auto',
        'background-color': '#f0f2f5',
      }"
    >
      <!--  -->
      <UserPreview :user="currentUser" />
    </a-drawer>
  </section>
</template>
<script>
import UsersMultiple from "@/components/UsersMultiple";
import UserPreview from "@/components/UserPreview/index";
import { mapGetters } from "vuex";
const moment = require("moment-timezone");

export default {
  components: {
    UsersMultiple,
    UserPreview,
  },
  data() {
    this.lastFetchId = 0;
    return {
      urlJoin: "",
      // /
      searchValue: "",
      // /
      currentUser: undefined,
      visibleUserView: false,
      // /
      visibleConfirm: false,
      //  /
      form: this.$form.createForm(this),
      visible: false,
      isEditGroup: false,
      groupId: undefined,
      // /
      data: [],
      loading: false,
      listQuery: {
        // _limit: 100,
        pagination: {
          page: 1,
        },
        filters: {
          name: {
            $contains: undefined,
          },
        },
        sort: {
          createdAt: "DESC",
        },
      },
      // /
      tagsUsers: [],
      tagsAdmins: [],
      groupNameEdit: "",
    };
  },
  mounted() {
    this.listQuery.pagination.page = 1;
    this.getData((res) => {
      this.loading = false;
      //console.log(res);
      this.data = this.processData(res);
    });
  },
  computed: {
    ...mapGetters(["role", "isAdmin"]),
  },
  methods: {
    getLastTestId(user) {
      // console.log('getLastTestId', user);
      const test = this.getLastTest(user);
      // console.log(test);
      return test.udid;
    },
    getLastTestText(user) {
      // console.log('getLastTestText', user);
      const test = this.getLastTest(user);
      return moment(test.dateend).format("DD.MM.YYYY, HH:mm");
    },
    async shareClicked(url) {
      try {
        await this.$copyText(url);
        this.$notification["success"]({
          message: this.$t("ui.linkCopied"),
          description: false,
        });
      } catch (e) {
        this.$notification["error"]({
          message: this.$t("ui.ErrorOccurred"),
          description: false,
        });
      }
    },
    getLastTest(user) {
      let test = null;
      let lastDate = new Date();
      lastDate.setYear(0);
      for (let i = 0; i < user.testresults.length; i++) {
        // console.log('user.testresults[i]', user.testresults[i]);
        if (new Date(user.testresults[i].dateend) > lastDate) {
          lastDate = new Date(user.testresults[i].dateend);
          test = user.testresults[i];
        }
      }
      return test;
    },
    editGroup(group) {
      // if (!this.isAdmin) {
      //   return;
      // }
      this.groupId = group.id;
      this.isEditGroup = true;
      this.visible = true;
      this.urlJoin = group.udid;
      this.tagsAdmins = group.moderators;
      this.tagsUsers = group.usersRegular;
      // this.groupNameEdit = group.name;
      this.$nextTick(() => {
        this.form.setFieldsValue({ name: group.name });
      });
    },
    processData(res) {
      const tempData = [];

      for (let j = 0; j < res.length; j++) {
        let record = res[j];
        // console.log(record);
        record["key"] = record.id;
        for (let i = 0; i < record.usersRegular.length; i++) {
          record.usersRegular[i].key = record.usersRegular[i].id;
        }
        tempData.push(record);
      }
      return tempData;
    },
    async onSubmitGroup() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          //console.log("onSubmitGroup", this.tagsUsers);
          const data = {
            name: values.name,
            moderators: this.tagsAdmins.map((user) => {
              return user.id;
            }),
            usersRegular: this.tagsUsers.map((user) => {
              return user.id;
            }),
          };
          let response = null;
          if (!this.isEditGroup) {
            response = await this.$store.dispatch("groups/createGroup", {
              data,
            });

            response.key = response.id;
            this.data = [response].concat(this.data);
          } else {
            response = await this.$store.dispatch("groups/updateGroup", {
              id: this.groupId,
              data,
            });

            response.key = response.id;
            for (let i = 0; i < this.data.length; i++) {
              if (this.data[i].id === response.id) {
                this.data[i] = response;
                break;
              }
            }
          }
          this.$notification["success"]({
            message: this.$t("ui.SuccessOccurred"),
            description: false,
          });
          this.onClose();
        }
      });
    },
    onClose() {
      this.visible = false;
      this.tagsAdmins = [];
      this.tagsUsers = [];
    },
    onCloseUserVice() {
      this.currentUser = undefined;
      this.visibleUserView = false;
    },
    viewUser(group, record) {
      // console.log(group, record);
      this.currentUser = record;
      this.visibleUserView = true;
    },
    removeFromGroup(group, record) {
      const store = this.$store;
      this.$confirm({
        title: "Вы действительно хотите исключить пользователя из группы?",
        onOk() {
          let index = -1;
          for (let i = 0; i < group.usersRegular.length; i++) {
            if (group.usersRegular[i].id === record.id) {
              index = i;
              break;
            }
          }
          if (index !== -1) {
            group.usersRegular.splice(index, 1);
            const data = {
              usersRegular: group.usersRegular.map((user) => user.id),
            };
            store.dispatch("groups/updateGroup", {
              id: group.id,
              data,
            });
          }
        },
        onCancel() {},
      });
    },
    // getRowKey(record, index) {
    //   //console.log('getRowKey', record, index);
    //   return record.id;
    // },
    // getRowKeyInner(record, index) {
    //   //console.log('getRowKeyInner', record, index);
    //   return record.usersRegular[index].id;
    // },
    // getExpandedData(record) {
    //   //console.log(record);
    //   return record.usersRegular;
    // },
    onSearch() {
      // console.log(value);
      const value = this.searchValue;

      if (value === "") {
        this.listQuery.filters.name = {
          $contains: undefined,
        };
      } else {
        this.listQuery.filters.name = {
          $contains: value,
        };
      }
      this.listQuery.pagination.page = 1;
      this.getData((res) => {
        this.loading = false;
        this.data = this.processData(res);
        //console.log(this.data);
      });
    },
    addGroup() {
      this.urlJoin = "";
      this.tagsAdmins = [];
      this.tagsUsers = [];
      this.form.setFieldsValue({ name: null });

      this.isEditGroup = false;
      // console.log("addGroup Clicked");
      this.visible = true;
    },
    getData(callback) {
      this.$store
        .dispatch("groups/fetchGroupsList", this.listQuery)
        .then((results) => {
          // console.log(results);
          callback(results);
        });
    },
    // /
    changeCondition(e) {
      this.condition = e.target.checked;
    },
    confirm() {
      this.visibleConfirm = false;
      message.success("Next step.");
    },
    cancel() {
      this.visibleConfirm = false;
      message.error("Click on cancel.");
    },
    handleVisibleChange(visible) {
      if (!visible) {
        this.visibleConfirm = false;
        return;
      }
      // Determining condition before show the popconfirm.
      // console.log(this.condition);
      if (this.condition) {
        this.confirm(); // next step
      } else {
        this.visibleConfirm = true;
      }
    },
  },
};
</script>
