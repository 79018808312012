<template>
  <a-tabs class="container-user-preview">
    <a-tab-pane tab="Хронология" key="1">
      <timelineSimple :testResults="testResultsReverse" />
    </a-tab-pane>
    <a-tab-pane tab="Сонди 1" key="2" v-if="user && user.testresults">
      <!--  -->
      <sondiSlider :user="user" />
      <a-tabs class="container">
        <a-tab-pane tab="Передний План" key="1"
          ><sondiTable :results="testResults" :isFront="true"
        /></a-tab-pane>
        <a-tab-pane tab="Задний План" key="2"
          ><sondiTable :results="testResults" :isFront="false"
        /></a-tab-pane>
      </a-tabs>
    </a-tab-pane>
    <a-tab-pane tab="Сонди 2" key="3" v-if="user && user.testresults">
      <!--  -->
      <a-divider class="dividerTitle"> "Тепловизор" </a-divider>
      <GraphThermo :results="testResults" :full="false" />
      <a-divider class="dividerTitle"> Гипертония </a-divider>
      <GraphThermo :results="testResults" :full="true" />
      <a-divider class="dividerTitle"> Среднее арифметическое </a-divider>
      <GraphMiddle :results="testResults" />
    </a-tab-pane>
  </a-tabs>
</template>
<script>
import GraphThermo from "@/components/Interpretations/GraphThermo";
import GraphMiddle from "@/components/Interpretations/GraphMiddle";
import timelineSimple from "./components/timeline-simple.vue";
import sondiSlider from "./components/sondi-slider.vue";
import sondiTable from "./components/szondi-table.vue";
export default {
  props: {
    user: Object,
  },
  components: {
    timelineSimple,
    GraphThermo,
    GraphMiddle,
    sondiSlider,
    sondiTable,
  },
  data() {
    return {
      testResults: [],
      testResultsReverse: [],
    };
  },
  mounted() {
    // console.log(this.user);
    if (this.user && this.user.testresults) {
      this.testResultsReverse = this.user.testresults.reverse();

      this.testResults = this.user.testresults.filter(
        (tr) => tr.testtype === "Szondi"
      );
    }
  },
  // computed: {
  //   dayIndexProp: {
  //     get() {
  //       //console.log('dayIndexProp', this.dayIndex);
  //       return this.dayIndex;
  //     },
  //     set(val) {
  //       this.dayIndex = val;
  //     }
  //   }
  // },

  methods: {},
};
</script>
<style>
.container-user-preview {
  /* width: 90%; */
  /* max-width: 1000px; */
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.ant-timeline-item-head-custom {
  background: #f0f2f5;
}

.ant-slider-rail {
  background: #0c93a4;
}
</style>
