var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('div',{style:({ margin: '20px' })},[_c('div',{style:({
        'max-width': '950px',
        'text-align': 'left',
        'margin-left': 'auto',
        'margin-right': 'auto',
        display: 'flex',
      })},[_c('span',{staticStyle:{"display":"flex","flex-flow":"wrap"}},[_c('a-dropdown',{style:({ 'min-width': '100px' })},[_c('a',{staticClass:"ant-dropdown-link",staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s("Роль")+" "),_c('a-icon',{attrs:{"type":"down"}})],1),_c('a-menu',{attrs:{"slot":"overlay"},on:{"click":_vm.handleMenuClick},slot:"overlay"},[_c('a-menu-item',{key:""},[_c('a',[_vm._v(_vm._s(_vm.$t("temp.all"))+" ")])]),_vm._l((_vm.roles),function(item){return _c('a-menu-item',{key:item.id},[_c('a',[_vm._v(_vm._s(item.name)+" ")])])})],2)],1),_c('a-input',{staticStyle:{"width":"200px"},attrs:{"placeholder":"поиск пользователя"},on:{"change":_vm.onSearchInput},model:{value:(_vm.searchValue),callback:function ($$v) {_vm.searchValue=$$v},expression:"searchValue"}},[_c('a-icon',{attrs:{"slot":"addonAfter","type":"search"},slot:"addonAfter"})],1),(_vm.selectedRowKeys && _vm.selectedRowKeys.length > 0 && _vm.isAdmin)?_c('span',{staticStyle:{"margin-left":"auto"}},[_c('a-button',{staticStyle:{"width":"200px"},attrs:{"type":"danger"},on:{"click":_vm.blockUsers}},[_c('a-icon',{attrs:{"type":"close"}}),_vm._v(" Заблокировать")],1),_c('a-button',{staticStyle:{"width":"200px"},attrs:{"type":"success"},on:{"click":_vm.unblockUsers}},[_c('a-icon',{attrs:{"type":"check"}}),_vm._v(" Разблокировать")],1),_c('a-button',{staticStyle:{"width":"200px"},attrs:{"type":"primary"},on:{"click":_vm.addToGroupUsers}},[_c('a-icon',{attrs:{"type":"plus"}}),_vm._v(" Добавить в группу")],1)],1):_vm._e()],1)])]),_c('a-table',{attrs:{"rowSelection":_vm.rowSelection,"dataSource":_vm.data,"rowKey":_vm.getRowKey,"scroll":{ y: 450, x: 800 },"sticky":"","pagination":{
      current: _vm.currentPage,
      pageSize: 10,
      total: _vm.data.length,
      pageSizeOptions: ['10', '20', '30', '40'],
    }},on:{"change":_vm.onPaginationChanged}},[_c('a-table-column',{attrs:{"title":"Имя","align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(text, record){return [_c('span',{staticStyle:{"text-align":"left"}},[_c('editable-cell',{attrs:{"text":text.firstname},on:{"change":function($event){return _vm.onCellChange(record.id, 'firstname', $event)}}})],1)]}}])}),_c('a-table-column',{attrs:{"title":"Фамилия","align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(text, record){return [_c('span',{staticStyle:{"text-align":"left","justify-content":"left","width":"100%"}},[_c('editable-cell',{attrs:{"text":text.secondname},on:{"change":function($event){return _vm.onCellChange(record.id, 'secondname', $event)}}})],1)]}}])}),_c('a-table-column',{attrs:{"title":"Email","align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(text, record){return [_c('a',{staticStyle:{"text-align":"left","justify-content":"left","width":"100%"},on:{"click":function($event){return _vm.viewUser(text, record)}}},[_vm._v(" "+_vm._s(text.email)+" ")])]}}])}),(_vm.isAdmin)?_c('a-table-column',{attrs:{"title":"Роль","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(text, record){return [_c('span',{style:({ color: _vm.getNameColor(text), 'text-align': 'center' })},[_c('editable-dropdown-cell',{attrs:{"text":text.role.name,"options":_vm.roles},on:{"change":function($event){return _vm.onCellRoleChange(record.id, 'role', $event)}}})],1)]}}],null,false,3004006843)}):_vm._e()],1),_c('a-modal',{attrs:{"title":'Добавить ' +
      _vm.selectedRowKeys.length +
      ' пользовате' +
      (_vm.selectedRowKeys.length === 1 ? 'ля ' : 'лей ') +
      'в группу'},on:{"ok":_vm.handleOk,"cancel":_vm.handleCancel},model:{value:(_vm.visibleGroupPopup),callback:function ($$v) {_vm.visibleGroupPopup=$$v},expression:"visibleGroupPopup"}},[_c('a-radio-group',{attrs:{"buttonStyle":"solid"},model:{value:(_vm.selectedGroup),callback:function ($$v) {_vm.selectedGroup=$$v},expression:"selectedGroup"}},_vm._l((_vm.groupsAll),function(item){return _c('a-radio-button',{key:item.id,staticStyle:{"margin":"5px"},attrs:{"value":item}},[_vm._v(_vm._s(item.name))])}),1)],1),(_vm.currentUser)?_c('a-drawer',{attrs:{"title":_vm.currentUser.firstname +
      ' ' +
      _vm.currentUser.secondname +
      ' (' +
      _vm.currentUser.email +
      ')',"width":'100%',"visible":_vm.visibleUserView,"wrapStyle":{
      height: 'calc(100%)',
      overflow: 'auto',
      'background-color': '#f0f2f5',
    }},on:{"close":_vm.onCloseUserVice}},[_c('UserPreview',{attrs:{"user":_vm.currentUser}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }