<template>
  <section>
    <div :style="{ margin: '20px' }">
      <div
        :style="{
          'max-width': '950px',
          'text-align': 'left',
          'margin-left': 'auto',
          'margin-right': 'auto',
          display: 'flex',
        }"
      >
        <span style="display: flex; flex-flow: wrap">
          <a-dropdown :style="{ 'min-width': '100px' }">
            <a class="ant-dropdown-link" style="font-size: 14px">
              {{ "Роль" }} <a-icon type="down" />
            </a>
            <a-menu slot="overlay" @click="handleMenuClick">
              <a-menu-item key="">
                <a>{{ $t("temp.all") }} </a>
              </a-menu-item>
              <a-menu-item :key="item.id" v-for="item in roles">
                <a>{{ item.name }} </a>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
          <a-input
            placeholder="поиск пользователя"
            v-model="searchValue"
            style="width: 200px"
            @change="onSearchInput"
          >
            <a-icon slot="addonAfter" type="search" />
          </a-input>

          <span
            style="margin-left: auto"
            v-if="selectedRowKeys && selectedRowKeys.length > 0 && isAdmin"
          >
            <a-button type="danger" style="width: 200px" @click="blockUsers"
              ><a-icon type="close" /> Заблокировать</a-button
            >
            <a-button type="success" style="width: 200px" @click="unblockUsers"
              ><a-icon type="check" /> Разблокировать</a-button
            >
            <a-button
              type="primary"
              style="width: 200px"
              @click="addToGroupUsers"
              ><a-icon type="plus" /> Добавить в группу</a-button
            >
          </span>
        </span>
      </div>
    </div>
    <a-table
      :rowSelection="rowSelection"
      :dataSource="data"
      :rowKey="getRowKey"
      :scroll="{ y: 450, x: 800 }"
      sticky
      @change="onPaginationChanged"
      :pagination="{
        current: currentPage,
        pageSize: 10,
        total: data.length,
        pageSizeOptions: ['10', '20', '30', '40'],
      }"
    >
      <a-table-column title="Имя" align="left">
        <template slot-scope="text, record">
          <span style="text-align: left">
            <editable-cell
              :text="text.firstname"
              @change="onCellChange(record.id, 'firstname', $event)"
            />
          </span>
        </template>
      </a-table-column>
      <a-table-column title="Фамилия" align="left">
        <template slot-scope="text, record">
          <span style="text-align: left; justify-content: left; width: 100%">
            <editable-cell
              :text="text.secondname"
              @change="onCellChange(record.id, 'secondname', $event)"
            />
          </span>
        </template>
      </a-table-column>

      <a-table-column title="Email" align="left">
        <template slot-scope="text, record">
          <a
            style="text-align: left; justify-content: left; width: 100%"
            @click="viewUser(text, record)"
          >
            {{ text.email }}
          </a>
        </template>
      </a-table-column>
      <a-table-column v-if="isAdmin" title="Роль" align="center">
        <template slot-scope="text, record">
          <span :style="{ color: getNameColor(text), 'text-align': 'center' }">
            <editable-dropdown-cell
              :text="text.role.name"
              :options="roles"
              @change="onCellRoleChange(record.id, 'role', $event)"
            />
          </span>
        </template>
      </a-table-column>
    </a-table>
    <a-modal
      :title="
        'Добавить ' +
        selectedRowKeys.length +
        ' пользовате' +
        (selectedRowKeys.length === 1 ? 'ля ' : 'лей ') +
        'в группу'
      "
      v-model="visibleGroupPopup"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-radio-group v-model="selectedGroup" buttonStyle="solid">
        <a-radio-button
          style="margin: 5px"
          v-for="item in groupsAll"
          :value="item"
          :key="item.id"
          >{{ item.name }}</a-radio-button
        >
      </a-radio-group>
    </a-modal>
    <a-drawer
      v-if="currentUser"
      :title="
        currentUser.firstname +
        ' ' +
        currentUser.secondname +
        ' (' +
        currentUser.email +
        ')'
      "
      :width="'100%'"
      @close="onCloseUserVice"
      :visible="visibleUserView"
      :wrapStyle="{
        height: 'calc(100%)',
        overflow: 'auto',
        'background-color': '#f0f2f5',
      }"
    >
      <!--  -->
      <UserPreview :user="currentUser" />
    </a-drawer>
  </section>
</template>

<script>
import EditableCell from "@/components/EditableCell";
import EditableDropdownCell from "@/components/EditableDropdownCell";
import { mapGetters } from "vuex";

import UserPreview from "@/components/UserPreview/index";
export default {
  components: {
    EditableCell,
    EditableDropdownCell,
    UserPreview,
  },
  data() {
    return {
      currentPage: 1,
      totalUsers: 0,
      // /
      searchValue: "",
      // /
      currentUser: undefined,
      visibleUserView: false,
      // /
      selectedRowKeys: [],
      roles: [],
      data: [],
      groupsAll: [],
      loading: false,
      selectedGroup: undefined,
      visibleGroupPopup: false,
      listQuery: {
        pagination: {
          page: 1,
        },
        filters: {
          $and: [
            {
              role: {
                id: {
                  $eq: undefined,
                },
              },
            },
            {
              $or: [
                {
                  email: {
                    $contains: undefined,
                  },
                },
                {
                  firstname: {
                    $contains: undefined,
                  },
                },
              ],
            },
          ],
        },
        sort: {
          createdAt: "DESC",
        },
      },
    };
  },
  async mounted() {
    this.listQuery.pagination.page = 1;
    this.getData((res) => {
      this.loading = false;
      this.data = res;
    });
    this.roles = await this.$store.dispatch("users/FetchRoles");
    this.roles = this.roles.filter((item) => item.name !== "Public");
    this.totalUsers = await this.$store.dispatch("users/countUser");
    // console.log(this.totalUsers);
  },
  computed: {
    ...mapGetters(["role", "isAdmin"]),
    rowSelection() {
      const { selectedRowKeys } = this;
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          // console.log(
          //   `selectedRowKeys: ${selectedRowKeys}`,
          //   "selectedRows: ",
          //   selectedRows
          // );
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.selectedRowKeys = selectedRowKeys;
        },
        getCheckboxProps: (record) => ({
          props: {
            disabled: record.name === "Disabled User", // Column configuration not to be checked
            name: record.name,
          },
        }),
      };
    },
  },
  methods: {
    onCloseUserVice() {
      this.currentUser = undefined;
      this.visibleUserView = false;
    },
    viewUser(group, record) {
      // console.log(group, record);
      this.currentUser = record;
      this.visibleUserView = true;
    },
    async addToGroupUsers() {
      const listQuery = {
        sort: {
          createdAt: "DESC",
        },
      };
      await this.$store
        .dispatch("groups/fetchGroupsList", listQuery)
        .then((results) => {
          // console.log(results);
          this.groupsAll = results;
          this.visibleGroupPopup = true;
        });
    },
    handleCancel() {
      this.visibleGroupPopup = false;
    },
    async handleOk() {
      // for (let i = 0; i < this.selectedRowKeys.length; i++) {
      //   await this.onCellChange(this.selectedRowKeys[i], 'blocked', null);
      // }
      // console.log(this.selectedGroup);
      const data = {
        usersRegular: this.selectedGroup.usersRegular
          ? this.selectedGroup.usersRegular.map((item) => item.id)
          : [],
      };
      // console.log(data);
      for (let i = 0; i < this.selectedRowKeys.length; i++) {
        let found = false;
        for (let j = 0; j < data.usersRegular.length; j++) {
          if (data.usersRegular[j] === this.selectedRowKeys[i]) {
            found = true;
            break;
          }
        }
        if (!found) {
          data.usersRegular.push(this.selectedRowKeys[i]);
        }
      }

      // console.log(data);
      await this.$store.dispatch("groups/updateGroup", {
        id: this.selectedGroup.id,
        data,
      });
      // console.log(this.selectedGroup);
      this.visibleGroupPopup = false;
    },
    async unblockUsers() {
      for (let i = 0; i < this.selectedRowKeys.length; i++) {
        await this.onCellChange(this.selectedRowKeys[i], "blocked", null);
      }
    },
    async blockUsers() {
      for (let i = 0; i < this.selectedRowKeys.length; i++) {
        await this.onCellChange(this.selectedRowKeys[i], "blocked", true);
      }
    },
    async onCellRoleChange(key, dataIndex, value) {
      // console.log(key, dataIndex, value);

      const role = this.roles.find(function (element) {
        return element.name === value;
      });
      // console.log(role);
      const data = {};
      data[dataIndex] = role.id;
      await this.$store.dispatch("users/updateUser", { id: key, data });

      if (role) {
        for (let i = 0; i < this.data.length; i++) {
          if (this.data[i].id === key) {
            this.data[i][dataIndex] = role;
            break;
          }
        }
      }
    },
    onPaginationChanged(pagination, filters, sorter, { currentDataSource }) {
      // console.log(pagination, filters, sorter);
      // console.log(this.currentPage);
      this.currentPage = pagination.current;
      // this.listQuery._start = (this.currentPage - 1) * this.listQuery._limit;
      // this.onSearch();
    },
    async onCellChange(key, dataIndex, value) {
      // console.log(key, dataIndex, value);
      const data = {};
      data[dataIndex] = value;
      await this.$store.dispatch("users/updateUser", { id: key, data });
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i].id === key) {
          this.data[i][dataIndex] = value;
          break;
        }
      }
    },
    onDelete(key) {
      const dataSource = [...this.dataSource];
      this.dataSource = dataSource.filter((item) => item.key !== key);
    },
    // handleAdd() {
    //   const { count, dataSource } = this;
    //   const newData = {
    //     key: count,
    //     name: `Edward King ${count}`,
    //     age: 32,
    //     address: `London, Park Lane no. ${count}`
    //   };
    //   this.dataSource = [...dataSource, newData];
    //   this.count = count + 1;
    // },
    onSearchInput() {
      this.listQuery.pagination.page = 1;
      this.onSearch();
    },
    onSearch() {
      const value = this.searchValue;

      this.listQuery.filters.$and[1].$or[0].email = {
        $contains: value,
      };
      this.listQuery.filters.$and[1].$or[1].firstname = {
        $contains: value,
      };

      this.getData((res) => {
        this.loading = false;
        this.data = res;
      });
    },
    handleMenuClick(e) {
      if (e.key === "") {
        this.listQuery.filters.$and[0].role = {
          id: {
            $eq: undefined,
          },
        };
      } else {
        this.listQuery.filters.$and[0].role = {
          id: {
            $eq: e.key,
          },
        };
      }
      this.listQuery.pagination.page = 1;
      this.getData((res) => {
        this.loading = false;
        this.data = res;
      });
    },
    getNameColor(text) {
      if (text.blocked) {
        return "red";
      }
      switch (text.role.name) {
        case "администратор":
          return "blue";
        case "интерпретатор":
          return "green";
        default:
          return "black";
      }
    },
    getRowKey(record, index) {
      return record.id;
    },
    getData(callback) {
      const userListQuery = {
        pagination: {
          pageSize: 10,
          page: 1,
        },
        filters: {
          ...this.listQuery.filters,
        },
        sort: {
          createdAt: "DESC",
        },
      };

      const newData = [];

      this.$store
        .dispatch("users/fetchUsersList", userListQuery)
        .then((results) => {
          for (let i = 0; i < results.length; i++) {
            newData.push(results[i]);
          }

          for (let i = 0; i < results.length; i++) {
            let found = false;
            for (let j = 0; j < newData.length; j++) {
              if (newData[j].id === results[i].id) {
                found = true;
              }
            }
            if (!found) {
              newData.push(results[i]);
            }
          }

          callback(newData);
        });
    },
  },
};
</script>
<style>
.editable-cell {
  position: relative;
}

.editable-cell-input-wrapper,
.editable-cell-text-wrapper {
  padding-right: 24px;
}

.editable-cell-text-wrapper {
  padding: 5px 24px 5px 5px;
}

.editable-cell-icon,
.editable-cell-icon-check {
  position: absolute;
  right: 0;
  width: 20px;
  cursor: pointer;
}

.editable-cell-icon {
  line-height: 18px;
  display: none;
}

.editable-cell-icon-check {
  line-height: 28px;
}

.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}

.editable-cell-icon:hover,
.editable-cell-icon-check:hover {
  color: #108ee9;
}

.editable-add-btn {
  margin-bottom: 8px;
}
</style>
