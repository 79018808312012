var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-stage',{ref:"chart",staticStyle:{"margin":"10px"},attrs:{"id":_vm.ident,"config":_vm.stageSize}},[_c('v-layer',[_c('v-text',{attrs:{"listening":false,"config":{
        text: _vm.title,
        fontSize: _vm.fontSizeNormal,
        fill: 'rgb(59,148,165)',
        x: _vm.xOffset - 30,
        y: _vm.yOffset + _vm.blockHeight * 6 + 1 + 100,
        width: 200,
        align: 'center',
        rotation: -90,
      }}}),_c('v-text',{attrs:{"listening":false,"config":{
        text: '+',
        fontSize: _vm.fontSizeBig,
        fill: 'rgba(234,97,86,1)',
        x: _vm.xOffset + _vm.blockWidth * 8 + 30 * _vm.resizeCoef - 15,
        y: _vm.yOffset + _vm.blockHeight * 3 + 1 - 10,
        width: 40,
        height: 40,
        verticalAlign: 'middle',
        align: 'center',
      }}}),_c('v-text',{attrs:{"listening":false,"config":{
        text: '-',
        fontSize: _vm.fontSizeBig,
        fill: 'rgba(97,97,229	,1)',
        x: _vm.xOffset + _vm.blockWidth * 8 + 30 * _vm.resizeCoef - 15,
        y: _vm.yOffset + _vm.blockHeight * 8 + 1 + 10,
        width: 40,
        height: 40,
        verticalAlign: 'middle',
        align: 'center',
      }}}),_vm._l((_vm.dataPlus),function(item,index){return _c('span',{key:'top' + index},[_vm._l((1),function(column){return _c('span',{key:'column-top-top' + column},[_c('v-rect',{attrs:{"listening":false,"config":{
            x: _vm.xOffset + _vm.blockWidth * index,
            y: _vm.yOffset + _vm.blockHeight * (column - 1 - 1),
            width: _vm.blockWidth,
            height: _vm.blockHeight,
            fill: 'rgba(240,242,245,1)',
            shadowBlur: 1,
          }}})],1)}),_vm._l((6),function(column){return _c('span',{key:'column-top-top-colored' + column},[_c('v-rect',{attrs:{"listening":false,"config":{
            x: _vm.xOffset + _vm.blockWidth * index,
            y: _vm.yOffset + _vm.blockHeight * (column - 1),
            width: _vm.blockWidth,
            height: _vm.blockHeight,
            fill:
              item[column - 1] === 0
                ? 'rgba(240,242,245,1)'
                : _vm.pickHexRed(item[column - 1] * (1 / _vm.total)),
            shadowBlur: 1,
          }}})],1)})],2)}),_vm._l((_vm.dataMinus),function(item,index){return _c('span',{key:'bottom' + index},_vm._l((6),function(column){return _c('span',{key:'column-top' + column},[_c('v-rect',{attrs:{"listening":false,"config":{
            x: _vm.xOffset + _vm.blockWidth * index,
            y: _vm.yOffset + _vm.blockHeight * (column + 5) + 2,
            width: _vm.blockWidth,
            height: _vm.blockHeight,
            fill:
              item[column - 1] === 0
                ? 'rgba(240,242,245,1)'
                : _vm.pickHexBlue(item[column - 1] * (1 / _vm.total)),
            shadowBlur: 1,
          }}})],1)}),0)}),_c('v-line',{attrs:{"listening":false,"config":{
        points: [
          _vm.xOffset,
          _vm.yOffset + _vm.blockHeight * 6,
          _vm.xOffset + _vm.blockWidth * 8,
          _vm.yOffset + _vm.blockHeight * 6 + 1,
        ],
        stroke: 'gray',
        strokeWidth: 3,
        lineCap: 'round',
        lineJoin: 'round',
      }}})],2),_c('v-layer',[_c('v-line',{attrs:{"listening":false,"config":{
        points: [
          _vm.xOffset,
          _vm.yOffset + _vm.blockHeight * 0,
          _vm.xOffset,
          _vm.yOffset + _vm.blockHeight * 12 + 2,
        ],
        stroke: 'gray',
        strokeWidth: 2,
        lineCap: 'round',
        lineJoin: 'round',
      }}}),_c('v-line',{attrs:{"listening":false,"config":{
        points: [
          _vm.xOffset + _vm.blockWidth * 8,
          _vm.yOffset + _vm.blockHeight * 0,
          _vm.xOffset + _vm.blockWidth * 8,
          _vm.yOffset + _vm.blockHeight * 12 + 2,
        ],
        stroke: 'gray',
        strokeWidth: 2,
        lineCap: 'round',
        lineJoin: 'round',
      }}}),_c('v-line',{attrs:{"listening":false,"config":{
        points: [_vm.xOffset, _vm.yOffset, _vm.xOffset + _vm.blockWidth * 8, _vm.yOffset],
        stroke: 'gray',
        strokeWidth: 2,
        lineCap: 'round',
        lineJoin: 'round',
      }}}),_c('v-line',{attrs:{"listening":false,"config":{
        points: [
          _vm.xOffset,
          _vm.yOffset + _vm.blockHeight * 12 + 2,
          _vm.xOffset + _vm.blockWidth * 8,
          _vm.yOffset + _vm.blockHeight * 12 + 2,
        ],
        stroke: 'gray',
        strokeWidth: 2,
        lineCap: 'round',
        lineJoin: 'round',
      }}}),_vm._l((_vm.columns),function(item,index){return _c('span',{key:'columns-top' + index + 100},[_c('v-rect',{attrs:{"listening":false,"config":{
          x: _vm.xOffset + _vm.blockWidth * index * 2,
          y: _vm.yOffset - _vm.blockHeight * 2,
          width: _vm.blockWidth * 2,
          height: _vm.blockHeight,
          fill: 'rgba(240,242,245,1)',
          shadowBlur: 1,
        }}}),_c('v-text',{attrs:{"listening":false,"config":{
          text: item,
          fontSize: _vm.fontSizeNormal,
          fill: 'black',
          x: _vm.xOffset + _vm.blockWidth + _vm.blockWidth * index * 2 - 25,
          y: _vm.yOffset - _vm.blockHeight * 2,
          width: 50,
          height: _vm.blockHeight,
          align: 'center',
          verticalAlign: 'middle',
        }}})],1)}),_vm._l((_vm.vectors),function(item,index){return _c('v-text',{key:'vector' + index + 1000,attrs:{"listening":false,"config":{
        text: item,
        fontSize: _vm.fontSizeSmall,
        fill: 'black',
        x: _vm.xOffset + _vm.blockWidth / 2 + _vm.blockWidth * index - 25,
        y: _vm.yOffset - _vm.blockHeight,
        width: 50,
        height: _vm.blockHeight,
        align: 'center',
        verticalAlign: 'middle',
      }}})})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }