<template>
  <div class="editable-cell">
    <div v-if="editable" class="editable-cell-input-wrapper">
      <a-select v-model="value" :value="value" style="width: 150px">
        <a-select-option
          v-for="item in options"
          :key="item.id"
          :value="item.name"
          @click="handleChange(item.name)"
          >{{ item.name }}</a-select-option
        >
      </a-select>
      <!-- <a-input :value="value" @change="handleChange" @pressEnter="check" /><a-icon
        type="check"
        class="editable-cell-icon-check"
        @click="check"
      /> -->
    </div>
    <div v-else class="editable-cell-text-wrapper">
      {{ value || " " }}
      <a-icon type="edit" class="editable-cell-icon" @click="edit" />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    text: String,
    options: Array,
  },
  data() {
    return {
      editable: false,
      value: this.text,
    };
  },
  methods: {
    handleChange(e) {
      // console.log('handleChange', e);
      const value = e;
      this.value = value;
      this.editable = false;
      this.$emit("change", this.value);
    },
    edit() {
      this.editable = true;
    },
  },
};
</script>
