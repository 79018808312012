<template>
  <v-stage ref="chart" :id="ident" :config="stageSize" style="margin: 10px">
    <v-layer>
      <!-- text -->
      <v-text
        :listening="false"
        :config="{
          text: title,
          fontSize: fontSizeNormal,
          fill: 'rgb(59,148,165)',
          x: xOffset - 30,
          y: yOffset + blockHeight * 6 + 1 + 100,
          width: 200,
          align: 'center',
          rotation: -90,
        }"
      />
      <v-text
        :listening="false"
        :config="{
          text: '+',
          fontSize: fontSizeBig,
          fill: 'rgba(234,97,86,1)',
          x: xOffset + blockWidth * 8 + 30 * resizeCoef - 15,
          y: yOffset + blockHeight * 3 + 1 - 10,
          width: 40,
          height: 40,
          verticalAlign: 'middle',
          align: 'center',
        }"
      />
      <v-text
        :listening="false"
        :config="{
          text: '-',
          fontSize: fontSizeBig,
          fill: 'rgba(97,97,229	,1)',
          x: xOffset + blockWidth * 8 + 30 * resizeCoef - 15,
          y: yOffset + blockHeight * 8 + 1 + 10,
          width: 40,
          height: 40,
          verticalAlign: 'middle',
          align: 'center',
        }"
      />

      <!--  -->
      <!-- vectors deviders -->
      <v-text
        :listening="false"
        :config="{
          text: resultLabelString,
          fontSize: fontSizeNormal,
          fill: 'rgb(59,148,165)',
          x: xOffset + blockWidth * 4 - 150,
          y: yOffset + blockHeight * 13 + 10,
          width: 300,
          height: blockHeight,
          verticalAlign: 'middle',
          align: 'center',
        }"
      />

      <!-- <v-circle
        :config="{
          x: 10,
          y: padding,
          radius: 5,
          fill: 'black'
        }"
      /> -->
      <!-- up -->
      <span v-for="(item, index) in dataPlus" :key="'top' + index">
        <span v-for="column in 7 - item" :key="'column-top-top' + column">
          <v-rect
            :listening="false"
            :config="{
              x: xOffset + blockWidth * index,
              y: yOffset + blockHeight * (column - 1 - 1),
              width: blockWidth,
              height: blockHeight,
              fill: 'rgba(240,242,245,1)',
              shadowBlur: 1,
            }"
          />
        </span>
        <span v-for="column in item" :key="'column-top' + column">
          <v-rect
            :listening="false"
            :config="{
              x: xOffset + blockWidth * index,
              y: yOffset + blockHeight * (5 - item + column),
              width: blockWidth,
              height: blockHeight,
              fill: 'rgba(234,97,86,1)',
              shadowBlur: 1,
            }"
          />
        </span>
        <v-text
          :listening="false"
          v-if="dataPlus[index] !== 0"
          :config="{
            text: dataPlus[index],
            fontSize: 10,
            fill: 'black',
            x: xOffset + blockWidth / 2 + blockWidth * index - 20,
            y: yOffset + blockHeight / 2 + blockHeight * (6 - item) - 20,
            width: 40,
            height: 40,
            verticalAlign: 'middle',
            align: 'center',
          }"
        />
      </span>

      <!-- top  -->
      <!-- <span v-for="(item, index) in dataPlus" :key="'top' + index">
        <span v-for="column in 6 - item" :key="'column-top-top' + column">
          <v-rect :listening="false"
            :config="{
              x: xOffset + blockWidth * index,
              y: yOffset + blockHeight * (column - 1),
              width: blockWidth,
              height: blockHeight,
              fill: 'rgba(240,242,245,1)',
              shadowBlur: 1
            }"
          />
        </span> -->

      <!-- down -->
      <span v-for="(item, index) in dataMinus.length" :key="'bottom' + index">
        <span v-for="column in -dataMinus[index]" :key="'column-top' + column">
          <v-rect
            :listening="false"
            :config="{
              x: xOffset + blockWidth * index,
              y: yOffset + blockHeight * (column + 5) + 2,
              width: blockWidth,
              height: blockHeight,
              fill: 'rgba(97,97,229,1)',
              shadowBlur: 1,
            }"
          />
        </span>
        <span
          v-for="column in 7 + dataMinus[index]"
          :key="'column-bottom' + column"
        >
          <v-rect
            :listening="false"
            :config="{
              x: xOffset + blockWidth * index,
              y: yOffset + blockHeight * (5 - dataMinus[index] + column) + 2,
              width: blockWidth,
              height: blockHeight,
              fill: 'rgba(240,242,245,1)',
              shadowBlur: 1,
            }"
          />
        </span>
        <v-text
          :listening="false"
          v-if="dataMinus[index] !== 0"
          :config="{
            text: dataMinus[index],
            fontSize: 10,
            fill: 'black',
            x: xOffset + blockWidth / 2 + blockWidth * index - 20,
            y:
              yOffset +
              blockHeight / 2 +
              blockHeight * (5 - dataMinus[index]) +
              2 -
              20,
            width: 40,
            height: 40,
            verticalAlign: 'middle',
            align: 'center',
          }"
        />
      </span>
      <v-line
        :listening="false"
        :config="{
          points: [
            xOffset,
            yOffset + blockHeight * 6,
            xOffset + blockWidth * 8,
            yOffset + blockHeight * 6 + 1,
          ],
          stroke: 'gray',
          strokeWidth: 3,
          lineCap: 'round',
          lineJoin: 'round',
        }"
      />
    </v-layer>
    <v-layer>
      <!-- lines -->
      <v-line
        :listening="false"
        :config="{
          points: [
            xOffset,
            yOffset + blockHeight * 0,
            xOffset,
            yOffset + blockHeight * 12 + 2,
          ],
          stroke: 'gray',
          strokeWidth: 2,
          lineCap: 'round',
          lineJoin: 'round',
        }"
      />
      <v-line
        :listening="false"
        :config="{
          points: [
            xOffset + blockWidth * 8,
            yOffset + blockHeight * 0,
            xOffset + blockWidth * 8,
            yOffset + blockHeight * 12 + 2,
          ],
          stroke: 'gray',
          strokeWidth: 2,
          lineCap: 'round',
          lineJoin: 'round',
        }"
      />
      <v-line
        :listening="false"
        :config="{
          points: [xOffset, yOffset, xOffset + blockWidth * 8, yOffset],
          stroke: 'gray',
          strokeWidth: 2,
          lineCap: 'round',
          lineJoin: 'round',
        }"
      />
      <!-- <v-line :listening="false"
        :config="{
          points: [xOffset, yOffset + blockHeight * 0, xOffset + blockWidth * 8, yOffset - blockHeight * 2],
          stroke: 'gray',
          strokeWidth: 2,
          lineCap: 'round',
          lineJoin: 'round'
        }"
      /> -->
      <v-line
        :listening="false"
        :config="{
          points: [
            xOffset,
            yOffset + blockHeight * 12 + 2,
            xOffset + blockWidth * 8,
            yOffset + blockHeight * 12 + 2,
          ],
          stroke: 'gray',
          strokeWidth: 2,
          lineCap: 'round',
          lineJoin: 'round',
        }"
      />
      <!-- vectors -->
      <span v-for="(item, index) in columns" :key="'columns-top' + index + 100">
        <v-rect
          :listening="false"
          :config="{
            x: xOffset + blockWidth * index * 2,
            y: yOffset - blockHeight * 2,
            width: blockWidth * 2,
            height: blockHeight,
            fill: 'rgba(240,242,245,1)',
            shadowBlur: 1,
          }"
        />
        <v-text
          :listening="false"
          :config="{
            text: item,
            fontSize: fontSizeNormal,
            fill: 'black',
            x: xOffset + blockWidth + blockWidth * index * 2 - 25,
            y: yOffset - blockHeight * 2,
            width: 50,
            height: blockHeight,
            align: 'center',
            verticalAlign: 'middle',
          }"
        />
      </span>
      <v-text
        :listening="false"
        v-for="(item, index) in vectors"
        :key="'vector' + index + 1000"
        :config="{
          text: item,
          fontSize: fontSizeSmall,
          fill: 'black',
          x: xOffset + blockWidth / 2 + blockWidth * index - 25,
          y: yOffset - blockHeight,
          width: 50,
          height: blockHeight,
          align: 'center',
          verticalAlign: 'middle',
        }"
      />
      <!-- vectors values-->
      <span
        v-for="(item, index) in vectorsValues"
        :key="'vector-bottom-bottom-values' + index + 10000"
      >
        <v-rect
          :listening="false"
          :config="{
            x: xOffset + blockWidth * index,
            y: yOffset + blockHeight * 13 + 2 + blockHeight * 2 + 10,
            width: blockWidth,
            height: blockHeight,
            fill: 'rgba(240,242,245,1)',
            shadowBlur: 1,
          }"
        />
        <v-text
          :listening="false"
          :config="{
            text: vectors[index] + ' ' + item,
            fontSize: fontSizeSmall,
            fill: 'black',
            x: xOffset + blockWidth / 2 + blockWidth * index - 25,
            y: yOffset + blockHeight * 13 + 2 + blockHeight * 2 + 10,
            width: 50,
            height: blockHeight,
            align: 'center',
            verticalAlign: 'middle',
          }"
        />
      </span>
      <span
        v-for="(item, index) in vectorsValues.length / 2"
        :key="'columns-bottom' + index + 100000"
      >
        <v-rect
          :listening="false"
          :config="{
            x: xOffset + blockWidth * index * 2,
            y: yOffset + blockHeight * 13 + 2 + blockHeight + 10,
            width: blockWidth * 2,
            height: blockHeight,
            fill: 'rgba(240,242,245,1)',
            shadowBlur: 1,
          }"
        />
        <v-text
          :listening="false"
          :config="{
            text:
              columns[index] +
              ' [' +
              vectorsValues[index * 2] +
              ' ' +
              vectorsValues[index * 2 + 1] +
              ']',
            fontSize: fontSizeNormal,
            fill: 'black',
            x: xOffset + blockWidth + blockWidth * index * 2 - 50,
            y: yOffset + blockHeight * 13 + 2 + blockHeight + 10,
            width: 100,
            height: blockHeight,
            align: 'center',
            verticalAlign: 'middle',
          }"
        />
      </span>
      <v-text
        :listening="false"
        v-for="(item, index) in vectorsValues"
        :key="'vector-bottom-top-values' + index + 10"
        :config="{
          text: vectors[index] + ' ' + item,
          fontSize: fontSizeSmall,
          fill: 'black',
          x: xOffset + blockWidth / 2 + blockWidth * index - 25,
          y: yOffset + blockHeight * 12 + 2,
          width: 50,
          height: blockHeight,
          align: 'center',
          verticalAlign: 'middle',
        }"
      />
    </v-layer>
    <v-layer>
      <!-- lines up -->
      <!-- <v-line :listening="false"
        :config="{
          points: [xOffset, yOffset - blockHeight * 2, xOffset + blockWidth * 8, yOffset - blockHeight * 2],
          stroke: 'gray',
          strokeWidth: 1,
          lineCap: 'round',
          lineJoin: 'round'
        }"
      /> -->
      <!-- <v-line :listening="false"
        :config="{
          points: [xOffset, yOffset + blockHeight * 0, xOffset + blockWidth * 8, yOffset + blockHeight * 0],
          stroke: 'gray',
          strokeWidth: 1,
          lineCap: 'round',
          lineJoin: 'round'
        }"
      /> -->
      <!-- <v-line :listening="false"
        :config="{
          points: [
            xOffset + blockWidth * 0,
            yOffset - blockHeight * 2,
            xOffset + blockWidth * 0,
            yOffset + blockHeight * 0
          ],
          stroke: 'gray',
          strokeWidth: 1,
          lineCap: 'round',
          lineJoin: 'round'
        }"
      />
      <v-line :listening="false"
        :config="{
          points: [
            xOffset + blockWidth * 2,
            yOffset - blockHeight * 2,
            xOffset + blockWidth * 2,
            yOffset - blockHeight * 0
          ],
          stroke: 'gray',
          strokeWidth: 1,
          lineCap: 'round',
          lineJoin: 'round'
        }"
      />
      <v-line :listening="false"
        :config="{
          points: [
            xOffset + blockWidth * 4,
            yOffset - blockHeight * 2,
            xOffset + blockWidth * 4,
            yOffset - blockHeight * 0
          ],
          stroke: 'gray',
          strokeWidth: 1,
          lineCap: 'round',
          lineJoin: 'round'
        }"
      />
      <v-line :listening="false"
        :config="{
          points: [
            xOffset + blockWidth * 6,
            yOffset - blockHeight * 2,
            xOffset + blockWidth * 6,
            yOffset - blockHeight * 0
          ],
          stroke: 'gray',
          strokeWidth: 1,
          lineCap: 'round',
          lineJoin: 'round'
        }"
      />
      <v-line :listening="false"
        :config="{
          points: [
            xOffset + blockWidth * 8,
            yOffset - blockHeight * 2,
            xOffset + blockWidth * 8,
            yOffset - blockHeight * 0
          ],
          stroke: 'gray',
          strokeWidth: 1,
          lineCap: 'round',
          lineJoin: 'round'
        }"
      /> -->

      <!-- lines bottom -->
      <v-line
        :listening="false"
        :config="{
          points: [
            xOffset,
            yOffset + blockHeight * 14 + 2 + 10,
            xOffset + blockWidth * 8,
            yOffset + blockHeight * 14 + 2 + 10,
          ],
          stroke: 'gray',
          strokeWidth: 1,
          lineCap: 'round',
          lineJoin: 'round',
        }"
      />
      <v-line
        :listening="false"
        :config="{
          points: [
            xOffset,
            yOffset + blockHeight * 16 + 2 + 10,
            xOffset + blockWidth * 8,
            yOffset + blockHeight * 16 + 2 + 10,
          ],
          stroke: 'gray',
          strokeWidth: 1,
          lineCap: 'round',
          lineJoin: 'round',
        }"
      />
      <v-line
        :listening="false"
        :config="{
          points: [
            xOffset + blockWidth * 0,
            yOffset + blockHeight * 14 + 2 + 10,
            xOffset + blockWidth * 0,
            yOffset + blockHeight * 16 + 2 + 10,
          ],
          stroke: 'gray',
          strokeWidth: 1,
          lineCap: 'round',
          lineJoin: 'round',
        }"
      />
      <v-line
        :listening="false"
        :config="{
          points: [
            xOffset + blockWidth * 2,
            yOffset + blockHeight * 14 + 2 + 10,
            xOffset + blockWidth * 2,
            yOffset + blockHeight * 16 + 2 + 10,
          ],
          stroke: 'gray',
          strokeWidth: 1,
          lineCap: 'round',
          lineJoin: 'round',
        }"
      />
      <v-line
        :listening="false"
        :config="{
          points: [
            xOffset + blockWidth * 4,
            yOffset + blockHeight * 14 + 2 + 10,
            xOffset + blockWidth * 4,
            yOffset + blockHeight * 16 + 2 + 10,
          ],
          stroke: 'gray',
          strokeWidth: 1,
          lineCap: 'round',
          lineJoin: 'round',
        }"
      />
      <v-line
        :listening="false"
        :config="{
          points: [
            xOffset + blockWidth * 6,
            yOffset + blockHeight * 14 + 2 + 10,
            xOffset + blockWidth * 6,
            yOffset + blockHeight * 16 + 2 + 10,
          ],
          stroke: 'gray',
          strokeWidth: 1,
          lineCap: 'round',
          lineJoin: 'round',
        }"
      />
      <v-line
        :listening="false"
        :config="{
          points: [
            xOffset + blockWidth * 8,
            yOffset + blockHeight * 14 + 2 + 10,
            xOffset + blockWidth * 8,
            yOffset + blockHeight * 16 + 2 + 10,
          ],
          stroke: 'gray',
          strokeWidth: 1,
          lineCap: 'round',
          lineJoin: 'round',
        }"
      />
      <!-- middle -->
      <v-line
        :listening="false"
        :config="{
          points: [
            xOffset + blockWidth * 2,
            yOffset + blockHeight * 0,
            xOffset + blockWidth * 2,
            yOffset + blockHeight * 12 + 2,
          ],
          stroke: 'gray',
          strokeWidth: 1,
          lineCap: 'round',
          lineJoin: 'round',
        }"
      />
      <v-line
        :listening="false"
        :config="{
          points: [
            xOffset + blockWidth * 4,
            yOffset + blockHeight * 0,
            xOffset + blockWidth * 4,
            yOffset + blockHeight * 12 + 2,
          ],
          stroke: 'gray',
          strokeWidth: 1,
          lineCap: 'round',
          lineJoin: 'round',
        }"
      />
      <v-line
        :listening="false"
        :config="{
          points: [
            xOffset + blockWidth * 6,
            yOffset + blockHeight * 0,
            xOffset + blockWidth * 6,
            yOffset + blockHeight * 12 + 2,
          ],
          stroke: 'gray',
          strokeWidth: 1,
          lineCap: 'round',
          lineJoin: 'round',
        }"
      />
      <v-line
        :listening="false"
        :config="{
          points: [
            xOffset + blockWidth * 8,
            yOffset + blockHeight * 0,
            xOffset + blockWidth * 8,
            yOffset + blockHeight * 12 + 2,
          ],
          stroke: 'gray',
          strokeWidth: 1,
          lineCap: 'round',
          lineJoin: 'round',
        }"
      />
    </v-layer>
  </v-stage>
</template>

<script>
const width = 450; // window.innerWidth
const height = 600; // window.innerHeight
const windowInnerWidth = window.innerWidth;
export default {
  props: {
    ident: {
      type: String,
      default: "stage",
    },
    resultLabelString: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    columns: {
      type: Array,
      default: () => ["S", "P", "Sch", "C"],
    },
    vectors: {
      type: Array,
      default: () => ["h", "s", "e", "hy", "k", "p", "d", "m"],
    },
    vectorsValues: {
      type: Array,
      default: () => Array(8).fill("?"), // ["0", "±", "±", "±", "0", "0", "±", "0"]
    },
    dataMinus: {
      type: Array,
      default: () => Array(8).fill(-6), // [-1, -2, -2, -2, -1, -6, -2, -1]
    },
    dataPlus: {
      type: Array,
      default: () => Array(8).fill(6), // [6, 2, 2, 2, 1, 0, 2, 1]
    },
  },
  data() {
    return {
      windowInnerWidth,
      stageSize: {
        width,
        height,
      },
      blockWidth: 0,
      blockHeight: 0,
      xOffset: 30,
      yOffset: 80,

      tempIndex: 0,
      resizeCoef: 1,
      fontSizeSmall: 15,
      fontSizeBig: 60,
      fontSizeNormal: 20,
    };
  },
  mounted() {
    // let val = windowInnerWidth / 450;
    // if (val > 1) {
    //   val = 1;
    // }
    // if (val < 0.7) {
    //   val = 0.7;
    // }
    this.resizeCoef = windowInnerWidth > 600 ? 1 : 0.7; // val;
    // this.xOffset *= this.resizeCoef;
    this.yOffset *= this.resizeCoef;
    this.fontSizeSmall *= this.resizeCoef;
    this.fontSizeBig *= this.resizeCoef;
    this.fontSizeNormal *= this.resizeCoef;
    this.stageSize.width *= this.resizeCoef;
    this.stageSize.height *= this.resizeCoef;
    this.blockWidth = Math.round((360 * this.resizeCoef) / 8); // (this.stageSize.width - this.padding * 2) / 8;
    this.blockHeight = Math.round((360 * this.resizeCoef) / 12);
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
//
</style>
