<template>
  <a-list
    class="results-loadmore-list"
    item-layout="horizontal"
    :data-source="testResults"
  >
    <a-list-item slot="renderItem" slot-scope="item">
      <a
        slot="actions"
        @click="shareClicked($strapi.baseUrl + ('/results/' + item.udid))"
      >
        {{ $t("ui.share") }}
      </a>
      <a slot="actions">
        <router-link :to="'/results/' + item.udid">
          {{ $t("ui.view") }}
        </router-link>
      </a>
      <a-list-item-meta>
        <div slot="description" style="display: flex; flex-flow: column">
          <!--  -->
          <span> {{ getTime(item.dateend) }}</span>
          <span style="color: rgb(46, 69, 90)">
            {{ getCompletionTime(item) }}</span
          >
        </div>
        <a
          slot="title"
          :href="$strapi.baseUrl + '/results/' + item.udid"
          target="_blank"
          >{{ getTestName(item.testtype) }}
        </a>
        <a-avatar slot="avatar" :src="getTestIcon(item.testtype)" />
      </a-list-item-meta>
    </a-list-item>
  </a-list>
  <!-- <a-timeline
    v-if="testResults"
    style="max-width:300px; margin-left:auto;margin-right:auto;width:90%;margin-top:30px;"
  >
    <a-timeline-item
      color="green"
      v-for="item in testResults"
      :key="item.id"
      style="display: flex; margin-left: 30px;width: max-content;background: #f0f2f5;"
    >
      <a-avatar
        slot="dot"
        :src="getTestIcon(item.testtype)"
        style="background: #f0f2f5;"
      />
      <span style="margin-left:50px;display: flex;width: max-content;">
        <span style="width:30px;height:100%;"></span>
        <a target="_blank" :href="$strapi.baseUrl + '/results/' + item.udid">
          {{ getTime(item) }}
        </a>
      </span>
    </a-timeline-item>
  </a-timeline> -->
</template>
<script>
const moment = require("moment-timezone");

export default {
  props: {
    testResults: Array,
  },
  methods: {
    getCompletionTime(item) {
      if (!item.transactions) {
        return "-";
      }
      // console.log(item.transactions);
      let s = 0;
      const intervals = item.transactions
        .split(" ")
        .filter((item) => item !== null && item !== "");
      for (let i = 0; i < intervals.length; i++) {
        s += parseFloat(intervals[i]);
      }
      // result
      return (
        Math.floor(s / 60) + "м " + ("0" + Math.floor(s % 60)).slice(-2) + "с"
      );
    },
    getTestIcon(testType) {
      const list = this.$store.state.tests.list;
      for (let i = 0; i < list.length; i++) {
        if (testType === list[i].type) {
          return this.$strapi.storageUrl + list[i].image.url;
        }
      }
      return "";
    },
    getTime(testresults) {
      return moment(testresults).format("DD.MM.YYYY, HH:mm");
    },
    getTestName(testType) {
      switch (testType) {
        case "Quadro":
          return this.$t("temp.quadro-test");
        case "Szondi":
          return this.$t("temp.testszondi");
        case "default":
          return "";
      }
    },
    async shareClicked(url) {
      try {
        await this.$copyText(url);
        this.$notification["success"]({
          message: this.$t("ui.linkCopied"),
          description: false,
        });
      } catch (e) {
        this.$notification["error"]({
          message: this.$t("ui.ErrorOccurred"),
          description: false,
        });
      }
    },
  },
};
</script>

<style scoped>
.results-loadmore-list {
  max-width: 600px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.demo-loadmore-list {
  min-height: 350px;
}

#ant-list > .ant-list-item-extra-wrap {
  width: 100%;
}
</style>
