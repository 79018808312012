var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('div',{style:({ margin: '20px' })},[_c('div',{style:({
        'max-width': '750px',
        'text-align': 'left',
        'margin-left': 'auto',
        'margin-right': 'auto',
        display: 'flex',
      })},[_c('a-dropdown',{style:({ 'min-width': '130px' })},[_c('a',{staticClass:"ant-dropdown-link",staticStyle:{"font-size":"14px"}},[_vm._v(" Кол-во тестов "),_c('a-icon',{attrs:{"type":"down"}})],1),_c('a-menu',{attrs:{"slot":"overlay"},on:{"click":_vm.handleMenuClick},slot:"overlay"},[_c('a-menu-item',{key:_vm.total},[_c('a',[_vm._v(" Все ")])]),_c('a-menu-item',{key:"10"},[_c('a',[_vm._v(" 10 тестов ")])]),_c('a-menu-item',{key:"20"},[_c('a',[_vm._v(" 20 тестов ")])]),_c('a-menu-item',{key:"30"},[_c('a',[_vm._v(" 30 тестов ")])])],1)],1)],1)]),_c('a-table',{attrs:{"dataSource":_vm.getData(_vm.period),"rowKey":_vm.getRowKey,"scroll":{ x: 600 },"pagination":false}},[_c('a-table-column',{attrs:{"title":"","width":"200px"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('span',[_vm._v(" "+_vm._s(_vm.getTime(text))+" ")])]}}])}),_vm._l((_vm.vectors),function(item,index){return _c('a-table-column',{key:'vector' + index,attrs:{"title":"","width":"50px"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('div',{staticStyle:{"display":"flex"}},[_c('span',{class:text.colors[index] === 1 ? 'circle-span' : 'circle-span-empty',style:({
              'background-color':
                text.colors[index] === 0
                  ? 'transparent'
                  : _vm.getColor(text.values[index]),
              'padding-left': '5px',
              'padding-right': '5px',
              'white-space': 'nowrap',
            })},[_vm._v(" "+_vm._s(item + "" + text.values[index])+" ")]),_c('span',{staticStyle:{"color":"red","min-width":"10px","margin-left":"5px"}},[_vm._v(" "+_vm._s(text.valuesHyp[index])+" ")])])]}}],null,true)})})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }