var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[(_vm.testResults)?_c('a-timeline',{staticStyle:{"max-width":"500px","margin-left":"auto","margin-right":"auto","width":"90%","margin-top":"30px","padding-right":"60px"},attrs:{"mode":"alternate"}},_vm._l((_vm.testResults.length * 2),function(item,index){return _c('a-timeline-item',{key:item.id},[(index % 2 === 0)?_c('div',{staticStyle:{"display":"flex","flex-flow":"column"}},[_c('a',{style:({
            color: _vm.testResults[Math.floor(index / 2)].user
              ? '#0c93a4'
              : 'black',
          }),on:{"click":function($event){_vm.testResults[Math.floor(index / 2)].user
              ? _vm.openUser(_vm.testResults[Math.floor(index / 2)].user)
              : undefined}}},[_vm._v(_vm._s(_vm.testResults[Math.floor(index / 2)].user ? _vm.testResults[Math.floor(index / 2)].user.firstname + " " + _vm.testResults[Math.floor(index / 2)].user.secondname + " (" + _vm.testResults[Math.floor(index / 2)].user.email + ")" : "Анонимно")+" ")]),(_vm.isAdmin)?_c('span',{staticStyle:{"color":"rgb(46, 69, 90)"}},[_vm._v(" "+_vm._s(_vm.getTestIPDetails(_vm.testResults[Math.floor(index / 2)]))+" "),_c('a',{staticStyle:{"color":"#0c93a4"},attrs:{"href":_vm.getTestGeolocation(_vm.testResults[Math.floor(index / 2)]) !== '-'
                ? 'https://www.google.com/maps?&z=15&mrt=yp&t=k&q=' +
                  _vm.getTestGeolocation(_vm.testResults[Math.floor(index / 2)])
                : undefined,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.getTestGeolocation(_vm.testResults[Math.floor(index / 2)]) !== "-" ? "Google Map" : "")+" ")])]):_vm._e(),(_vm.isAdmin)?_c('a',{staticStyle:{"color":"#0c93a4"},attrs:{"href":_vm.getTestIP(_vm.testResults[Math.floor(index / 2)]) !== '-'
              ? 'https://ipgeolocation.io/ip-location/' +
                _vm.getTestIP(_vm.testResults[Math.floor(index / 2)])
              : undefined,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.getTestIP(_vm.testResults[Math.floor(index / 2)]))+" ")]):_vm._e()]):_vm._e(),(index % 2 === 1)?_c('a-avatar',{staticStyle:{"background":"#f0f2f5"},attrs:{"slot":"dot","src":_vm.getTestIcon(_vm.testResults[Math.floor(index / 2)].testtype)},slot:"dot"}):_vm._e(),(index % 2 === 1)?_c('a-list-item-meta',[_c('div',{staticStyle:{"display":"flex","flex-flow":"column"},attrs:{"slot":"description"},slot:"description"},[_c('span',[_vm._v(" "+_vm._s(_vm.getTime(_vm.testResults[Math.floor(index / 2)].dateend)))]),_c('span',{staticStyle:{"color":"rgb(46, 69, 90)"}},[_vm._v(" "+_vm._s(_vm.getCompletionTime(_vm.testResults[Math.floor(index / 2)])))])]),_c('a',{staticStyle:{"color":"#0c93a4"},attrs:{"slot":"title","href":_vm.$strapi.baseUrl +
            '/results/' +
            _vm.testResults[Math.floor(index / 2)].udid,"target":"_blank"},slot:"title"},[_vm._v(_vm._s(_vm.getTestName(_vm.testResults[Math.floor(index / 2)].testtype))+" ")])]):_vm._e()],1)}),1):_vm._e(),(_vm.currentUser)?_c('a-drawer',{attrs:{"title":_vm.currentUser.firstname +
      ' ' +
      _vm.currentUser.secondname +
      ' (' +
      _vm.currentUser.email +
      ')',"width":'100%',"visible":_vm.visibleUserView,"wrapStyle":{
      height: 'calc(100%)',
      overflow: 'auto',
      'background-color': '#f0f2f5',
    }},on:{"close":_vm.onCloseUserVice}},[_c('UserPreview',{attrs:{"user":_vm.currentUser}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }