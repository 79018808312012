var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('div',{style:({ margin: '20px' })},[_c('div',{style:({
        'max-width': '750px',
        'text-align': 'left',
        'margin-left': 'auto',
        'margin-right': 'auto',
        display: 'flex',
      })},[_c('a-dropdown',{style:({ 'min-width': '130px' })},[_c('a',{staticClass:"ant-dropdown-link",staticStyle:{"font-size":"14px"}},[_vm._v(" Кол-во тестов "),_c('a-icon',{attrs:{"type":"down"}})],1),_c('a-menu',{attrs:{"slot":"overlay"},on:{"click":_vm.handleMenuClick},slot:"overlay"},[_c('a-menu-item',{key:_vm.totalrecords},[_c('a',[_vm._v(" Все ")])]),_c('a-menu-item',{key:"10"},[_c('a',[_vm._v(" 10 тестов ")])]),_c('a-menu-item',{key:"20"},[_c('a',[_vm._v(" 20 тестов ")])]),_c('a-menu-item',{key:"30"},[_c('a',[_vm._v(" 30 тестов ")])])],1)],1)],1)]),_c('section',{staticClass:"tabsResultsNew"},[_c('SondiChart',{ref:"chartOne",attrs:{"ident":"chartOne","title":_vm.$t('test-szondi.front'),"result-label-string":_vm.$t('test-szondi.resultFront'),"data-plus":_vm.getData(_vm.period).frontPlus,"data-minus":_vm.getData(_vm.period).frontMinus,"total":_vm.totalrecords}}),_c('SondiChart',{ref:"chartTwo",attrs:{"ident":"chartTwo","title":_vm.$t('test-szondi.back'),"result-label-string":_vm.$t('test-szondi.resultBack'),"data-plus":_vm.getData(_vm.period).backPlus,"data-minus":_vm.getData(_vm.period).backMinus,"total":_vm.totalrecords}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }