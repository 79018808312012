<template>
  <div>
    <span v-for="tag in selectedTags" :key="tag.id">
      <a-tooltip v-if="tag.length > 20" :title="tag">
        <a-tag :closable="true" :afterClose="() => handleClose(tag)">
          {{ `${tag.slice(0, 20)}...` }}
        </a-tag>
      </a-tooltip>
      <a-tag v-else :closable="true" :afterClose="() => handleClose(tag)">
        {{ tag.email }}
      </a-tag>
    </span>
    <!-- <a-select
    showSearch
    :value="value"
    placeholder="input search text"
    style="width: 200px"
    :defaultActiveFirstOption="false"
    :showArrow="false"
    :filterOption="false"
    @search="handleSearch"
    @change="handleChange"
    :notFoundContent="null"
  > -->
    <a-select
      v-if="inputVisible"
      showSearch
      :value="value"
      placeholder="поиск пользователя"
      style="width: 200px"
      :defaultActiveFirstOption="false"
      :showArrow="false"
      :filterOption="false"
      @search="fetchUsers"
      @change="handleChange"
      :notFoundContent="null"
    >
      <a-select-option v-for="user in allUsers" :key="user.id">{{
        user.firstname + " " + user.secondname + " - " + user.email
      }}</a-select-option>
    </a-select>
    <a-tag
      v-else
      @click="showInput"
      style="background: #0c93a4; color: white; borderstyle: dashed"
    >
      <a-icon type="plus" /> Добавить
    </a-tag>
  </div>
</template>
<script>
export default {
  props: {
    tags: Array,
  },
  data() {
    return {
      inputVisible: false,
      allUsers: [],
      value: undefined,
      fetching: false,
      selectedTags: [],
    };
  },
  methods: {
    fetchUsers(value) {
    //   //console.log("fetching user", value);
      if (this.fetching) {
        return;
      }

      this.allUsers = [];

      this.fetching = true;

      const userListQuery = {
        pagination: {
          pageSize: 10,
          page: 1,
        },
        filters: {
          $or: [
            {
              email: {
                $contains: value,
              },
            },
            {
              firstname: {
                $contains: value,
              },
            },
          ],
        },
        sort: {
          createdAt: "DESC",
        },
      };

      //console.log(userListQuery);

      const newData = [];
      //   if (value === "") {
      //     userListQuery.filters.email = {
      //       $contains: undefined,
      //     };

      //     this.$store
      //       .dispatch("users/fetchUsersList", userListQuery)
      //       .then((results) => {
      //         userListQuery.filters.email = {
      //           $contains: undefined,
      //         };
      //         userListQuery.filters.firstname = {
      //           $contains: value,
      //         };
      //         for (let i = 0; i < results.length; i++) {
      //           newData.push(results[i]);
      //         }
      //       });
      //     this.allUsers = newData;
      //     this.fetching = false;
      //     return;
      //   }

      this.$store
        .dispatch("users/fetchUsersList", userListQuery)
        .then((results) => {
          for (let i = 0; i < results.length; i++) {
            newData.push(results[i]);
          }

          for (let i = 0; i < results.length; i++) {
            let found = false;
            for (let j = 0; j < newData.length; j++) {
              if (newData[j].id === results[i].id) {
                found = true;
              }
            }
            if (!found) {
              newData.push(results[i]);
            }
          }

          this.allUsers = newData;
          this.fetching = false;

          //   this.$store
          //     .dispatch("users/fetchUsersList", userListQuery)
          //     .then((resultsSecond) => {
          //       userListQuery.filters.firstname = {
          //         $contains: undefined,
          //       };
          //       userListQuery.filters.secondname = {
          //         $contains: value,
          //       };
          //       for (let i = 0; i < resultsSecond.length; i++) {
          //         let found = false;
          //         for (let j = 0; j < newData.length; j++) {
          //           if (newData[j].id === resultsSecond[i].id) {
          //             found = true;
          //           }
          //         }
          //         if (!found) {
          //           newData.push(resultsSecond[i]);
          //         }
          //       }

          //       this.$store
          //         .dispatch("users/fetchUsersList", userListQuery)
          //         .then((resultsThird) => {
          // for (let i = 0; i < resultsThird.length; i++) {
          //   let found = false;
          //   for (let j = 0; j < newData.length; j++) {
          //     if (newData[j].id === resultsThird[i].id) {
          //       found = true;
          //     }
          //   }
          //   if (!found) {
          //     newData.push(resultsThird[i]);
          //   }
          // }
          // this.allUsers = newData;
          // this.fetching = false;
          //         });
          //     });
        });
    },
    handleChange(value) {
      const selectedValue = value;

      let found = false;
      for (let i = 0; i < this.selectedTags.length; i++) {
        if (this.selectedTags[i].id === selectedValue.id) {
          found = true;
          break;
        }
      }
      if (!found) {
        this.selectedTags.push(
          this.allUsers.find((user) => user.id == selectedValue)
        );
      }
      this.inputVisible = false;
    },
    handleClose(removedTag) {
      //   const tags = this.tags.filter(tag => tag !== removedTag);
      //   //console.log('handleClose', tags);
      //   const index = this.tags.indexOf(item => item.id === removedTag.id);
      //   //console.log(removedTag, index);
      let index = -1;
      for (let i = 0; i < this.selectedTags.length; i++) {
        if (this.selectedTags[i].id === removedTag.id) {
          index = i;
        }
      }
      //   this.tags.splice(index, 1);
      //   //console.log(index, this.tags);
      if (index !== -1) {
        this.selectedTags.splice(index, 1);
      }
    },

    showInput() {
      this.inputVisible = true;
    },
  },
  watch: {
    tags() {
      this.selectedTags = this.tags;
    },
  },
  mounted() {
    this.selectedTags = this.tags;
  },
};
</script>
