<template>
  <section>
    <a-timeline
      v-if="testResults"
      mode="alternate"
      style="
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;
        width: 90%;
        margin-top: 30px;
        padding-right: 60px;
      "
    >
      <a-timeline-item
        v-for="(item, index) in testResults.length * 2"
        :key="item.id"
      >
        <div v-if="index % 2 === 0" style="display: flex; flex-flow: column">
          <a
            :style="{
              color: testResults[Math.floor(index / 2)].user
                ? '#0c93a4'
                : 'black',
            }"
            @click="
              testResults[Math.floor(index / 2)].user
                ? openUser(testResults[Math.floor(index / 2)].user)
                : undefined
            "
            >{{
              testResults[Math.floor(index / 2)].user
                ? testResults[Math.floor(index / 2)].user.firstname +
                  " " +
                  testResults[Math.floor(index / 2)].user.secondname +
                  " (" +
                  testResults[Math.floor(index / 2)].user.email +
                  ")"
                : "Анонимно"
            }}
          </a>
          <span v-if="isAdmin" style="color: rgb(46, 69, 90)">
            {{ getTestIPDetails(testResults[Math.floor(index / 2)]) }}
            <a
              style="color: #0c93a4"
              :href="
                getTestGeolocation(testResults[Math.floor(index / 2)]) !== '-'
                  ? 'https://www.google.com/maps?&z=15&mrt=yp&t=k&q=' +
                    getTestGeolocation(testResults[Math.floor(index / 2)])
                  : undefined
              "
              target="_blank"
            >
              {{
                getTestGeolocation(testResults[Math.floor(index / 2)]) !== "-"
                  ? "Google Map"
                  : ""
              }}
            </a>
          </span>
          <a
            v-if="isAdmin"
            style="color: #0c93a4"
            :href="
              getTestIP(testResults[Math.floor(index / 2)]) !== '-'
                ? 'https://ipgeolocation.io/ip-location/' +
                  getTestIP(testResults[Math.floor(index / 2)])
                : undefined
            "
            target="_blank"
          >
            {{ getTestIP(testResults[Math.floor(index / 2)]) }}
          </a>
        </div>
        <a-avatar
          v-if="index % 2 === 1"
          slot="dot"
          :src="getTestIcon(testResults[Math.floor(index / 2)].testtype)"
          style="background: #f0f2f5"
        />
        <a-list-item-meta v-if="index % 2 === 1">
          <div slot="description" style="display: flex; flex-flow: column">
            <!--  -->
            <span>
              {{ getTime(testResults[Math.floor(index / 2)].dateend) }}</span
            >
            <span style="color: rgb(46, 69, 90)">
              {{ getCompletionTime(testResults[Math.floor(index / 2)]) }}</span
            >
          </div>
          <a
            style="color: #0c93a4"
            slot="title"
            :href="
              $strapi.baseUrl +
              '/results/' +
              testResults[Math.floor(index / 2)].udid
            "
            target="_blank"
            >{{ getTestName(testResults[Math.floor(index / 2)].testtype) }}
          </a>
        </a-list-item-meta>
      </a-timeline-item>
    </a-timeline>
    <!--  -->
    <a-drawer
      v-if="currentUser"
      :title="
        currentUser.firstname +
        ' ' +
        currentUser.secondname +
        ' (' +
        currentUser.email +
        ')'
      "
      :width="'100%'"
      @close="onCloseUserVice"
      :visible="visibleUserView"
      :wrapStyle="{
        height: 'calc(100%)',
        overflow: 'auto',
        'background-color': '#f0f2f5',
      }"
    >
      <!--  -->
      <UserPreview :user="currentUser" />
    </a-drawer>
  </section>
</template>
<script>
const moment = require("moment-timezone");

import { mapGetters } from "vuex";
import UserPreview from "@/components/UserPreview/index";
export default {
  components: { UserPreview },
  data() {
    return {
      currentUser: undefined,
      visibleUserView: false,
      testResults: [],
    };
  },
  mounted() {
    var d = new Date();
    d.setDate(d.getDate() - 2); // two days ago

    const listQuery = {
      // _limit: 100,
      pagination: {
        page: 1,
      },
      filters: {
        testtype: {
          $eq: undefined,
        },
        datestart: {
          $gte: d,
        },
        dateend: {
          $lte: undefined,
        },
      },
      timeline: true,
      sort: {
        createdAt: 'DESC'
      },
    };
    this.$store
      .dispatch("testResults/fetchTestResultsListManage", listQuery)
      .then((results) => {
        //console.log(results);
        this.testResults = results;
      });
  },
  computed: {
    ...mapGetters(["role", "isAdmin"]),
  },
  methods: {
    getTestGeolocation(item) {
      if (item.security && item.security.ipData && item.security.ipData) {
        return (
          item.security.ipData.latitude + "+" + item.security.ipData.longitude
        );
      }
      // https://www.google.com/maps/dir/46.47750,+30.73260/46.4775,30.7326/@46.4779501,30.7323367,16z/
      return "-";
    },
    getTestIPDetails(item) {
      if (item.security && item.security.ipData && item.security.ipData) {
        return (
          item.security.ipData.city + ", " + item.security.ipData.country_name
        );
      }
      return "-";
    },
    getTestIP(item) {
      // console.log(item);
      if (item.security && item.security.ipData && item.security.ipData.ip) {
        return item.security.ipData.ip;
      }
      if (
        item.security &&
        item.security["request-header"] &&
        item.security["request-header"]["x-real-ip"]
      ) {
        return item.security["request-header"]["x-real-ip"];
      }
      if (
        item.security &&
        item.security["request-header"] &&
        item.security["request-header"]["x-forwarded-for"]
      ) {
        return item.security["request-header"]["x-forwarded-for"];
      }
      return "-";
    },
    getCompletionTime(item) {
      if (!item.transactions) {
        return "-";
      }
      // console.log(item.transactions);
      let s = 0;
      const intervals = item.transactions
        .split(" ")
        .filter((item) => item !== null && item !== "");
      for (let i = 0; i < intervals.length; i++) {
        s += parseFloat(intervals[i]);
      }
      // result
      return (
        Math.floor(s / 60) + "м " + ("0" + Math.floor(s % 60)).slice(-2) + "с"
      );
    },
    onCloseUserVice() {
      this.currentUser = undefined;
      this.visibleUserView = false;
    },
    async openUser(user) {
      // console.log(user);
      const userFull = await this.$store.dispatch("users/fetchUser", user.id);
      this.currentUser = userFull;
      this.visibleUserView = true;
    },
    getTestIcon(testType) {
      const list = this.$store.state.tests.list;
      for (let i = 0; i < list.length; i++) {
        if (testType === list[i].type) {
          return this.$strapi.storageUrl + list[i].image.url;
        }
      }
      return "";
    },
    getTime(testresults) {
      // console.log(testresults);
      return moment(new Date(testresults)).format("DD.MM.YYYY, HH:mm");
    },
    getTestName(testType) {
      switch (testType) {
        case "Quadro":
          return this.$t("temp.quadro-test");
        case "Szondi":
          return this.$t("temp.testszondi");
        case "default":
          return "";
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
