<template>
  <section>
    <div :style="{ margin: '20px' }">
      <div
        :style="{
          'max-width': '750px',
          'text-align': 'left',
          'margin-left': 'auto',
          'margin-right': 'auto',
          display: 'flex',
        }"
      >
        <a-dropdown :style="{ 'min-width': '130px' }">
          <a class="ant-dropdown-link" style="font-size: 14px">
            Кол-во тестов <a-icon type="down" />
          </a>
          <a-menu slot="overlay" @click="handleMenuClick">
            <a-menu-item :key="totalrecords">
              <a> Все </a>
            </a-menu-item>
            <a-menu-item key="10">
              <a> 10 тестов </a>
            </a-menu-item>
            <a-menu-item key="20">
              <a> 20 тестов </a>
            </a-menu-item>
            <a-menu-item key="30">
              <a> 30 тестов </a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
    </div>
    <section class="tabsResultsNew">
      <!--  -->
      <SondiChart
        ident="chartOne"
        ref="chartOne"
        :title="$t('test-szondi.front')"
        :result-label-string="$t('test-szondi.resultFront')"
        :data-plus="getData(period).frontPlus"
        :data-minus="getData(period).frontMinus"
        :total="totalrecords"
      />
      <SondiChart
        ident="chartTwo"
        ref="chartTwo"
        :title="$t('test-szondi.back')"
        :result-label-string="$t('test-szondi.resultBack')"
        :data-plus="getData(period).backPlus"
        :data-minus="getData(period).backMinus"
        :total="totalrecords"
      />
    </section>
  </section>
</template>

<script>
import SondiChart from "./SondiChart.vue";
export default {
  components: {
    SondiChart,
  },
  props: {
    full: Boolean,
    results: {
      default: () => [],
      type: Array,
    },
  },
  data() {
    return {
      resultedData: undefined,
      totalrecords: 1,
      // /
      period: 0,
      total: 1,
    };
  },
  computed: {},
  mounted() {
    this.total = this.results.length;
    this.period = this.results.length;
  },
  methods: {
    getData(period) {
      const newresults = this.results.slice(0, period);

      const resultedData = {
        frontPlus: Array(8)
          .fill(0)
          .map(() => Array(6).fill(0)),
        frontMinus: Array(8)
          .fill(0)
          .map(() => Array(6).fill(0)),
        backPlus: Array(8)
          .fill(0)
          .map(() => Array(6).fill(0)),
        backMinus: Array(8)
          .fill(0)
          .map(() => Array(6).fill(0)),
      };
      let totalrecords = 0;

      // const frontPlusValues = Array(8).fill(0);
      // const frontMinusValues = Array(8).fill(0);

      // const backPlusValues = Array(8).fill(0);
      // const backMinusValues = Array(8).fill(0);
      // console.log(resultedData);
      for (let i = 0; i < newresults.length; i++) {
        if (newresults[i].testtype !== "Szondi") {
          continue;
        }
        totalrecords++;
        let hypertoniaFound = false;
        // console.log(newresults[i].result);
        // /frontPlus
        for (let j = 0; j < newresults[i].result.frontPlus.length; j++) {
          // console.log(
          //   newresults[i].result.frontPlus[j],
          //   6 - newresults[i].result.frontPlus[j]
          // );
          if (newresults[i].result.frontPlus[j] === 0) {
            continue;
          }
          if (this.full && newresults[i].result.frontPlus[j] < 4) {
            continue;
          } else {
            hypertoniaFound = true;
          }
          // frontPlusValues[j] += newresults[i].result.frontPlus[j];
          const start = 6 - newresults[i].result.frontPlus[j];
          for (let k = start; k < 6; k++) {
            resultedData.frontPlus[j][k]++;
          }
        }
        // /backPlus
        for (let j = 0; j < newresults[i].result.backPlus.length; j++) {
          if (newresults[i].result.backPlus[j] === 0) {
            continue;
          }
          if (this.full && newresults[i].result.backPlus[j] < 4) {
            continue;
          } else {
            hypertoniaFound = true;
          }
          // backPlusValues[j] += newresults[i].result.backPlus[j];
          const start = 6 - newresults[i].result.backPlus[j];
          for (let k = start; k < 6; k++) {
            resultedData.backPlus[j][k]++;
          }
        }
        // /frontMinus
        for (let j = 0; j < newresults[i].result.frontMinus.length; j++) {
          if (newresults[i].result.frontMinus[j] === 0) {
            continue;
          }
          if (this.full && newresults[i].result.frontMinus[j] > -4) {
            continue;
          } else {
            hypertoniaFound = true;
          }
          // frontMinusValues[j] -= newresults[i].result.frontMinus[j];
          const end = -newresults[i].result.frontMinus[j];
          for (let k = 0; k < end; k++) {
            resultedData.frontMinus[j][k]++;
          }
        }
        // /backMinus
        for (let j = 0; j < newresults[i].result.backMinus.length; j++) {
          if (newresults[i].result.backMinus[j] === 0) {
            continue;
          }
          if (this.full && newresults[i].result.backMinus[j] > -4) {
            continue;
          } else {
            hypertoniaFound = true;
          }
          // backMinusValues[j] -= newresults[i].result.backMinus[j];
          const end = -newresults[i].result.backMinus[j];
          for (let k = 0; k < end; k++) {
            resultedData.backMinus[j][k]++;
          }
        }

        if (this.full && !hypertoniaFound) {
          totalrecords--;
        }
      }
      // console.log(resultedData);
      // this.resultedData = resultedData;
      // console.log(
      //   frontPlusValues,
      //   frontMinusValues,
      //   backPlusValues,
      //   backMinusValues,
      //   totalrecords
      // );

      this.totalrecords = totalrecords === 0 ? 1 : totalrecords;
      return resultedData;
    },
    handleMenuClick(e) {
      this.period = e.key;
    },
  },
};
</script>
<style lang="scss" scoped>
.resultCaption,
.resultNormal {
  text-align: left;
  max-width: 350px;
}
.resultCaption {
  font-weight: bold;
}
.tabsResultsNew {
  width: 90vw;
  margin-left: auto;
  margin-right: auto;
  /* min-height: 700px; */
  // margin-top: 30px;

  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  justify-content: center;

  margin-bottom: 30px;
  text-align: center;
  max-width: 800px;
}

.tabsResultsNew {
  /* width: 100vw; */
  max-width: 1000px;
  /* width: fit-content; */
}

.sondi-results
  > div.tabsResults.ant-tabs.ant-tabs-top.ant-tabs-line
  > div.ant-tabs-bar.ant-tabs-top-bar {
  text-align: center;
}

.resultsTitle {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: large;
}
</style>
