<template>
  <section>
    <a-tabs class="container">
      <a-tab-pane tab="Пользователи" key="1" v-if="isAdmin"
        ><users
      /></a-tab-pane>
      <a-tab-pane tab="Группы" key="2"><groups /></a-tab-pane>
      <a-tab-pane tab="Хронология" key="3" v-if="isAdmin"
        ><timeline
      /></a-tab-pane>
    </a-tabs>
  </section>
</template>

<script>
import { users, groups, timeline } from "./components/index";

import { mapGetters } from "vuex";
export default {
  middleware: "uiDefaultState",
  components: {
    users,
    groups,
    timeline,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["role", "isAdmin"]),
  },
  methods: {
    async waitFor(ms) {
      return new Promise(function (resolve) {
        setTimeout(resolve, ms || 0);
      });
    },
    // async removeReplain() {
    //   const show = false;

    //   if (!process.server) {
    //     const x = document.getElementById("__replain_widget_iframe");
    //     if (x) {
    //       x.style.display = show ? "unset" : "none";
    //     }
    //   }

    //   await this.waitFor(2000);
    //   if (!process.server) {
    //     const x = document.getElementById("__replain_widget_iframe");
    //     if (x) {
    //       x.style.display = show ? "unset" : "none";
    //     }
    //   }

    //   await this.waitFor(5000);
    //   if (!process.server) {
    //     const x = document.getElementById("__replain_widget_iframe");
    //     if (x) {
    //       x.style.display = show ? "unset" : "none";
    //     }
    //   }
    // }
  },
  mounted() {
    // this.removeReplain();
  },
};
</script>
<style lang="scss" scoped>
.container {
  margin: 20px;
  margin-top: 30px; // 30px
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  max-width: 1200px;
  justify-content: center;
  align-items: center;
  text-align: center;
}
</style>
