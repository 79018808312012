<template>
  <section>
    <div :style="{ margin: '20px' }">
      <div
        :style="{
          'max-width': '750px',
          'text-align': 'left',
          'margin-left': 'auto',
          'margin-right': 'auto',
          display: 'flex',
        }"
      >
        <a-dropdown :style="{ 'min-width': '130px' }">
          <a class="ant-dropdown-link" style="font-size: 14px">
            Кол-во тестов <a-icon type="down" />
          </a>
          <a-menu slot="overlay" @click="handleMenuClick">
            <a-menu-item :key="total">
              <a> Все </a>
            </a-menu-item>
            <a-menu-item key="10">
              <a> 10 тестов </a>
            </a-menu-item>
            <a-menu-item key="20">
              <a> 20 тестов </a>
            </a-menu-item>
            <a-menu-item key="30">
              <a> 30 тестов </a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
    </div>
    <a-table
      :dataSource="getData(period)"
      :rowKey="getRowKey"
      :scroll="{ x: 600 }"
      :pagination="false"
    >
      <a-table-column title="" width="200px">
        <template slot-scope="text">
          <span>
            {{ getTime(text) }}
          </span>
        </template>
      </a-table-column>
      <a-table-column
        title=""
        v-for="(item, index) in vectors"
        :key="'vector' + index"
        width="50px"
      >
        <template slot-scope="text">
          <div style="display: flex">
            <span
              :class="
                text.colors[index] === 1 ? 'circle-span' : 'circle-span-empty'
              "
              :style="{
                'background-color':
                  text.colors[index] === 0
                    ? 'transparent'
                    : getColor(text.values[index]),
                'padding-left': '5px',
                'padding-right': '5px',
                'white-space': 'nowrap',
              }"
            >
              {{ item + "" + text.values[index] }}
            </span>
            <span style="color: red; min-width: 10px; margin-left: 5px">
              {{ text.valuesHyp[index] }}
            </span>
          </div>
        </template>
      </a-table-column>
    </a-table>
  </section>
</template>
<script>
const moment = require("moment-timezone");

export default {
  props: {
    results: Array,
    isFront: Boolean,
    vectors: {
      type: Array,
      default: () => ["h", "s", "e", "hy", "k", "p", "d", "m"],
    },
  },
  data() {
    return {
      resultedData: [],
      period: 0,
      total: 0,
    };
  },
  mounted() {
    this.total = this.results.length;
    this.period = this.results.length;
    this.resultedData = this.getData(this.period);
  },
  methods: {
    getData(period) {
      const newresult = this.results.slice(0, period);

      let data = [];
      const getter = this.isFront ? "frontResult" : "backResult";
      for (let i = 0; i < newresult.length; i++) {
        const values = [];
        const valuesHyp = [];
        const colors = Array(8).fill(0);
        for (let j = 0; j < this.vectors.length; j++) {
          values.push(newresult[i].result[getter][j].replace(/!/g, ""));
          valuesHyp.push(newresult[i].result[getter][j].replace(/[^!]/g, ""));
          // /
          if (i !== newresult.length - 1) {
            if (
              newresult[i + 1].result[getter][j].replace(/!/g, "") ===
              newresult[i].result[getter][j].replace(/!/g, "")
            ) {
              colors[j] = 1;
            }
          }
          // /
          if (i !== 0) {
            if (
              newresult[i - 1].result[getter][j].replace(/!/g, "") ===
              newresult[i].result[getter][j].replace(/!/g, "")
            ) {
              colors[j] = 1;
            }
          }
          // /
        }
        data.push({
          id: newresult[i].id,
          key: newresult[i].id,
          dateend: newresult[i].dateend,
          values,
          valuesHyp,
          colors,
        });
      }
      return data;
    },
    handleMenuClick(e) {
      this.period = e.key;
    },
    getTime(testresults) {
      // const d = new Date(testresults.dateend);
      // d.getMonth()
      return moment(testresults.dateend).format("DD.MM.YYYY, HH:mm");
    },
    getRowKey(record, index) {
      return record.id;
    },
    getColor(val) {
      switch (val) {
        case "+":
          return "rgb(255, 0, 0)";
        case "±":
          return "rgb(255, 50, 50)"; // rgb(50, 200, 20)';
        case "–":
          return "rgb(255, 100, 100)"; // rgb(100, 100, 255)';
        case "0":
          return "rgb(255, 150, 150)"; // rgba(12, 147, 164, 0.8)';
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.circle-span-empty,
.circle-span {
  display: block;
  height: 20px;
  width: 35px;
  line-height: 20px;

  -moz-border-radius: 15%; /* or 50% */
  border-radius: 15%; /* or 50% */

  background-color: #0c93a4; // rgb(255, 230, 230);
  color: white;
  text-align: center;
  font-size: 1em;
}

.circle-span-empty {
  color: black;
  background-color: transparent;
}
</style>
<style lang="less">
.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
  background: white;
}
</style>
