<template>
  <section>
    <a-slider
      v-if="resultedData != null"
      @change="onChange"
      :tipFormatter="formatter"
      :min="0"
      :max="testResults.length - 1"
      style="
        max-width: 500px;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
      "
    />
    <Graph ref="graph" :resulted-data="resultedData" v-if="renderComponent" />
  </section>
</template>
<script>
import Graph from "@/components/Interpretations/Graph";
const moment = require("moment-timezone");

export default {
  components: {
    Graph,
  },
  props: {
    user: Object,
  },
  data() {
    return {
      dayIndex: 0,
      testResults: [],
      resultedData: null,
      renderComponent: true,
    };
  },
  mounted() {
    if (this.user && this.user.testresults) {
      this.testResults = this.user.testresults.filter(
        (tr) => tr.testtype === "Szondi"
      );
    }

    this.resultedData = this.getTestResultResultedData(0);
  },
  methods: {
    forceRerender() {
      this.renderComponent = false;
      this.$nextTick(() => {
        this.renderComponent = true;
        // console.log("re-render start");
        this.$nextTick(() => {
          // console.log("re-render end");
        });
      });
    },
    getTestResultResultedData(index) {
      // console.log('getTestResultResultedData', this);
      if (
        !this.user ||
        !this.user.testresults ||
        index >= this.testResults.length
      ) {
        return null;
      }
      return this.testResults[index].result;
    },
    onChange(value) {
      // console.log(this.$refs.graph.$refs.chartOne.dataMinus);
      // console.log(this.$refs.graph.$refs.chartOne);
      this.dayIndex = value;
      // console.log(this.dayIndex);
      this.resultedData = this.getTestResultResultedData(value);
      // console.log(this.$refs.graph.$refs.chartTwo.dataMinusVal);
      this.$refs["graph"].$refs["chartTwo"].$refs["chart"].getStage().draw();
      // console.log(this.resultedData.backMinus);
      // this.$refs.graph.$refs.chartTwo.dataMinusVal = this.resultedData.backMinus;
      // console.log(this.$refs.graph.$refs.chartTwo);
      // this.$refs.graph.$forceUpdate();
      // console.log(this.$refs.graph.$refs.chartTwo.dataMinusVal );
      this.forceRerender();
      // Object.assign(
      //   {},
      //   this.resultedData,
      //   this.getTestResultResultedData(value)
      // );
    },
    formatter(value) {
      return `${this.getTime(this.user.testresults[value])}`;
    },
    getTime(testresults) {
      return moment(testresults.dateend).format("LL");
    },
  },
};
</script>
